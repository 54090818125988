import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikState } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { UsersInvitationType } from "src/@types/usersInvitationType";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { MailIcon } from "src/assets/icon/Mail";
import Illustration_doc from "src/assets/illustration_doc";
import { GetAsync, PostAsync } from "src/common/httpRequests";
import ErrorScreen from "src/components/ErrorScreen";
import { MySelectField } from "src/components/FormFields";
import { Option } from "src/components/hook-form/dense/RHFAutocomplete";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import * as Yup from "yup";

interface AssignWorkflowModalProps {
  isShowModal: boolean;
  showModalMethod: (docId: string | null) => void;
  documentId: string;
}

interface State {
  loading: boolean;
  users: Option[];
  errorMsg: string;
}

interface AssignWorkflowSchema {
  documentId: string;
  approvers: string[];
}

const AssignWorkflowModal: React.FC<AssignWorkflowModalProps> = (props) => {
  const { isShowModal, showModalMethod, documentId } = props;
  const { translate } = useLocales("workflow_screen");
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    loading: false,
    users: [],
    errorMsg: "",
  });

  const defaultValues = {
    documentId,
    approvers: [],
  };

  const workflowAssignSchema = Yup.object().shape({
    documentId: Yup.string(),
    approvers: Yup.array()
      .of(Yup.string())
      .required(translate("approvers_required"))
      .min(1, translate("approvers_required")),
  });

  const handleCloseModal = (
    reset: (
      nextState?: Partial<FormikState<AssignWorkflowSchema>> | undefined
    ) => void
  ) => {
    showModalMethod(null);
    reset();
  };

  const fetchOrganizationUsers = async () => {
    setState({ ...state, loading: true });
    await GetAsync(`${API_GATEWAY}/api/userService/Organization/users`)
      .then((res) => {
        if (res.status === 200) {
          const activeUsers: Option[] = res?.data?.users
            ?.filter((user: UsersInvitationType) => user.isActive)
            .map((activeUser: UsersInvitationType) => ({
              label: activeUser.userName,
              value: activeUser.userName,
            }));

          setState({ ...state, loading: false, users: activeUsers });
        } else {
          setState({ ...state, loading: false, errorMsg: res.data.Detail });
        }
      })
      .catch((err) => {
        setState({ ...state, loading: false, errorMsg: err });
      });
  };

  const handleAssignWorkflow = async (
    values: AssignWorkflowSchema,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: (
      nextState?: Partial<FormikState<AssignWorkflowSchema>> | undefined
    ) => void
  ) => {
    await PostAsync(
      `${API_GATEWAY}/api/workflowService/workflows/assign-workflow`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(translate("workflow_assigned_successfully"), {
            variant: "success",
          });
          setSubmitting(false);
          handleCloseModal(resetForm);
        } else {
          enqueueSnackbar(
            translate(res.data.Detail ?? "something_went_wrong"),
            {
              variant: "error",
            }
          );
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(translate(err ?? "something_went_wrong"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    fetchOrganizationUsers();
  }, []);

  const { errorMsg, loading, users } = state;

  return (
    <Dialog open={isShowModal} maxWidth="sm" fullWidth={true}>
      {loading ? (
        "Loading"
      ) : errorMsg ? (
        <ErrorScreen message={errorMsg} icon={<Illustration_doc />} />
      ) : (
        <Formik
          initialValues={defaultValues}
          validationSchema={workflowAssignSchema}
          onSubmit={(
            values: AssignWorkflowSchema,
            { setSubmitting, resetForm }
          ) => {
            handleAssignWorkflow(values, setSubmitting, resetForm);
          }}
        >
          {({ errors, setFieldValue, isSubmitting, resetForm }) => (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {translate("assign_workflow")}
                </DialogTitle>
                <IconButton
                  sx={{
                    height: "10px",
                    width: "8px",
                    mr: 4,
                    mt: 4,
                  }}
                  onClick={() => handleCloseModal(resetForm)}
                >
                  <CloseModalButton />
                </IconButton>
              </Box>
              <DialogContent>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontSize: "13px" }}
                >
                  {translate("assign_workflow_to_organization_users")}
                </Typography>
                <MySelectField
                  name="approvers"
                  label={translate("Approvers")}
                  placeholder={translate("Add a new email")}
                  startAdornment={
                    <MailIcon
                      height={24}
                      width={24}
                      sx={{
                        position: "absolute",
                        left: "6px",
                        top: "calc(50% - 16px)",
                        pointerEvents: "none",
                        color: errors?.approvers ? "#B72136" : "#657482",
                        zIndex: 10,
                      }}
                    />
                  }
                  options={users}
                  multiple
                  error={errors?.approvers}
                  onChange={(e) => {
                    setFieldValue(
                      `approvers`,
                      typeof e.target.value === "string" && e.target.value != ""
                        ? e.target.value.split(",")
                        : e.target.value
                    );
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Box display="flex" justifyContent="flex-end" gap={1}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => handleCloseModal(resetForm)}
                    sx={{
                      p: 0,
                    }}
                    disabled={isSubmitting}
                  >
                    {translate("cancel")}
                  </Button>
                  <LoadingButton
                    size="small"
                    type="submit"
                    loading={isSubmitting}
                    autoFocus
                    variant="contained"
                  >
                    {translate("assign")}
                  </LoadingButton>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default AssignWorkflowModal;
