import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// config
import { defaultLang } from "../config";
//
import enLocales from "./en";
import exportConfigurationEn from "./english/exportConfiguration";
import globalEn from "./english/global";
import integrationEn from "./english/integration";
import mappingsEn from "./english/mappings";
import transactionsEn from "./english/transactions";
import workflowsEn from "./english/workflows";
import esLocales from "./es";
import exportConfigurationEs from "./spanish/exportConfiguration";
import globalEs from "./spanish/global";
import integrationEs from "./spanish/integration";
import workflowsEs from "./spanish/workflows";

import mappingsEs from "./spanish/mappings";
import transactionsEs from "./spanish/transactions";
// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: { ...enLocales, ...globalEn },
        integration_screen: { ...integrationEn },
        workflow_screen: { ...workflowsEn },
        mapping_screen: { ...mappingsEn },
        exportConfiguration_screen: { ...exportConfigurationEn },
        transaction_screen: { ...transactionsEn },
      },
      es: {
        translations: { ...esLocales, ...globalEs },
        integration_screen: { ...integrationEs },
        workflow_screen: { ...workflowsEs },
        mapping_screen: { ...mappingsEs },
        exportConfiguration_screen: { ...exportConfigurationEs },
        transaction_screen: { ...transactionsEs },
      },
    },
    lng: localStorage.getItem("i18nextLng") || defaultLang.value,
    fallbackLng: "es",
    debug: false,
    fallbackNS: "translations",
    ns: [
      "translations",
      "integration_screen",
      "mapping_screen",
      "exportConfiguration_screen",
      "transaction_screen",
    ],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
