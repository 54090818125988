import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useSnackbar, VariantType } from "notistack";
import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import Xarrow from "react-xarrows";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Page from "src/components/Page";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import useTabs from "src/hooks/useTabs";
import { setScreen } from "src/redux/slices/reviewScreen";
import { partition } from "src/utils/partition";
import { DeleteAsync, GetAsync, PutAsync } from "../../common/httpRequests";
import { DocumentFinished } from "../../mixpanel/documentFinished";
import { store } from "../../redux/store";
import useDefaultQuery from "../Documents/Finance/hooks/useDefaultQuery";
import { ReviewAlertBox } from "../Notification/NotificationAlertBox";
import AccoutingPeriodDialog from "./Component/AccountingPeriodDialog";
import AssignWorkflowModal from "./Component/AssignWorkflowModal";
import { BreadCrumbs } from "./Component/BreadCrumbs";
import { createOcrBoundingBoxes } from "./Component/dataView/BoundingBox";
import PageDataView from "./Component/dataView/pageDataView";
import XMLDataView from "./Component/dataView/xmlDataView";
import DialogBox from "./Component/dialogBox";
import DeletedIndicator from "./Component/DocumentDeletedChip";
import DocumentOptionsButton from "./Component/DocumentOptionButton";
import GeneralTable from "./Component/itemsModalTable/general/GeneralTable";
import ItemsTable from "./Component/itemsModalTable/postProcessing/Table";
import ItemsTableTable from "./Component/itemsModalTable/preprocessing/Table";
import LowConfidence from "./Component/lowConfidence/lowConfidence";
import PostProcessing from "./Component/processing/PostProcessing";
import PreProcessed from "./Component/processing/PreProcessed";
import ReviewPageSkeleton from "./Component/reviewPageSkeleton";
import ReviewToolbarPanel from "./Component/reviewToolbar";
import SelfTrainingTag from "./Component/SelfTrainingTag";
import SelfTrainingTagDialog from "./Component/SelfTrainingTagDialog";
import { useErrorTranslation } from "./Component/useErrorMessage";
import { ReviewContext } from "./context/ReviewContext";
import { unifyItemFields } from "./helpers/unifyItems";
import { COLOR_LIST } from "./hooks/LabelColors";
import { generateAreaMap } from "./hooks/useAreaMap";
import { getDimensions, swapDimensions } from "./hooks/useDimensions";
import useItemsTable from "./hooks/useItemsTable";
import { applyColorToLabel, getLabeColor } from "./hooks/useLabels";
import usePanning from "./hooks/usePanning";
import useReviewToolbar from "./hooks/useReviewToolbar";
import useScroll from "./hooks/useScroll";
import "./style.css";
import { TReviewContextType } from "./types/reviewContext";
import { FormValidationProps } from "./types/types";

const FormValidation = ({
  approvalNotRequired,
  fileId,
  isModal = false,
  modalButtonLoading,
  setModalButtonLoading,
  ActionButtons = ({ isSubmitting }) => <></>,
  handleApprove = async () => {},
  readPermissions,
  writePermissions,
  handleClose,
}: FormValidationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const ref = useRef<FormikProps<any>>(null);
  const gridRef = useRef<any>(null);
  const [messages, setMessages] = useState<
    {
      messageKey: string;
      values: string[] | null;
    }[]
  >([]);
  const {
    label: label_,
    initial,
    dimensions,
    base64String,
    expectedValues,
    reviewResponse,
    tag,
  } = useContext<TReviewContextType>(ReviewContext);
  const {
    base64String: base64Strings,
    imageloading,
    setBase64String,
    fetchBase64String,
  } = base64String;
  const {
    setDocumentFieldsExpectedValues,
    setDocumentFieldsExpectedValueConfigs,
  } = expectedValues;
  const { initialState, setInitialState } = initial;
  const { width, height, rotation, setHeight, setWidth, setRotation } =
    dimensions;
  const { setLabelColorMap } = label_;
  const [MAP, setMAP] = useState({ name: "my-map", areas: [] as any[] });

  const [data, setData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageData, setCurrentPageData] = useState<any>([]);
  const {
    org: { enableAsyncReviewPage },
  } = store.getState()?.organization;
  const [state, setState] = useState({
    fileId: "",
    invoiceType: "",
  });
  const [openPostItems, setPostOpenItems] = useState(false);
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs("0");
  const [postProcessing, setPostProcessing] = useState({});
  const [postTableItem, setPostTableItem] = useState([]);
  const [postTableTitle, setPostTableTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentSource, setDocumentSource] = useState("");
  const COMPANY_ID = localStorage.getItem("company_id");
  const { translateErrorMessage } = useErrorTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(true);
  const [confidenceArray, setConfidenceArray] = useState<any[]>([]);
  const location = useLocation();
  const [documentClass, setDocumentClass] = useState("");
  const [url, setUrl] = useState("");
  const { path: documentPath } = useDefaultQuery();
  const navigation = () => {
    if (location?.key != "default") navigate(-1);
    else navigate(documentPath);
  };
  const [documentTypeField, setDocumentTypeField] = useState<any>({});
  const [rows, setRows] = useState([]);
  const tableRefs = useRef<any>({});
  const [enableSelfTraining, setEnableSelfTraining] = useState(false);
  const [isPolygonUpdated, setIsPolygonUpdated] = useState(false);
  const [openAccoutingPeriod, setOpenAccountingPeriod] = useState(false);
  const [accountingPeriod, setAccountingPeriod] = useState<any>(null);
  const [documentIsDeleted, setDocumentIsDeleted] = useState(false);
  const [isAssignWorkflowModalVisible, setIsAssignWorkflowModalVisible] =
    useState(false);
  const {
    open,
    handleCloseItemsTable,
    handleOpenItemsTable,
    itemsListKey,
    formState,
    dispatch,
  } = useItemsTable({ values: ref?.current?.values || {} });

  const [callback, setCallback] = useState<null | VoidFunction>(null);

  const onClickNext = (page: number = pageNo, callback_?: VoidFunction) => {
    if (totalPages > page) {
      let p = page + 1;
      let { height: h, width: w } = getDimensions(
        data?.[p - 1]?.height,
        data?.[p - 1]?.width,
        isScreen
      );
      setRotation(0);
      setHeight(h);
      setWidth(w);
      setPageData(data?.[p - 1], ref?.current?.values, p, h, w, 0);
      if (
        transformComponentRef?.current?.instance?.transformState?.scale &&
        transformComponentRef?.current?.instance?.transformState?.scale > 1
      ) {
        transformComponentRef?.current?.resetTransform();
      }
      if (callback_) {
        setCallback(() => callback_);
      }
      setPageNo(p);
      setCurrentPageData(data?.[p - 1]);
    }
  };

  const {
    axis,
    setAxis,
    boundingBox,
    xarrowColor,
    title,
    handleMouseScroll,
    removeArrowAxis,
    setXarrowColor,
    setBoundingBox,
    onMouseEnter,
    onMouseLeave,
    anchorEl,
    transformComponentRef,
    setAnchorEl,
  } = useScroll({ currentTab, tableRefs, onClickNext, pageNo });
  const { editOptionsProps, toolbarprops } = useReviewToolbar({
    MAP,
    setMAP,
    ...dimensions,
    transformComponentRef,
  });
  const [tagName, setTagName] = useState<string>("");
  const [isJsonHashValid, setIsJsonHashValid] = useState(true);
  const [isValidHash, setIsValidHash] = useState(true);
  const [removeTagName, setRemoveTagName] = useState<string>("");

  const handleToggleAssignWorkflowModal = () => {
    setIsAssignWorkflowModalVisible(!isAssignWorkflowModalVisible);
  };

  useEffect(() => {
    handleInvoiceDetail();
  }, []);

  function handlePostItemOpen(item, k: string) {
    setPostTableTitle(k);
    setPostTableItem(item);
    setPostOpenItems(true);
  }

  function handlePostItemsClose() {
    setPostTableTitle("");
    setPostTableItem([]);
    setPostOpenItems(false);
  }
  const TABS = [
    { value: "0", label: translate(`review_screen.extraction`) },
    { value: "1", label: translate(`review_screen.post_processing`) },
  ];

  const handleInvoiceDetail = async () => {
    setState((state) => ({
      ...state,
      fileId: fileId ?? "",
    }));
    if (fileId) {
      fetchData(fileId);
      let baseString = await fetchBase64String(fileId, 1, (response) => {
        setIsValidHash(response?.data?.isValidHash);
      });
      let base64Strings_ = base64Strings;
      base64Strings_[pageNo - 1] = baseString ?? "";
      setBase64String([...base64Strings_]);
    } else {
      setTimeout(() => {
        navigation();
      }, 0);
    }
  };

  const snackbar = (val: string, variant: VariantType, duration = 2000) => {
    setTimeout(() => {
      if (!isModal) {
        navigation();
      }
      enqueueSnackbar(`${val}`, { variant: variant, autoHideDuration: 7000 });
    }, duration);
  };

  const fetchData = async (fileId: string) => {
    setIsLoading(true);
    const response = await GetAsync(
      `${API_GATEWAY}/api/documentservice/DocumentReview/${fileId}`
    );
    try {
      if (response.status === 204) {
        snackbar("File is corrupted", "error");
      } else if ([400, 404].includes(response.status)) {
        snackbar(translate(response?.data?.Detail), "error");
        setTimeout(() => {
          navigation();
        }, 0);
      } else if (response.status === 500) {
        snackbar(translate("something_went_wrong"), "error");
        setTimeout(() => {
          navigation();
        }, 0);
      }
    } catch (err) {
      snackbar("File is corrupted", "error");
    }
    // let response = { data: res };
    if (response?.data?.accountingPeriod) {
      setAccountingPeriod(response?.data?.accountingPeriod);
    }
    setFileName(response?.data?.documentName);
    setMessages(response?.data?.messageKeys);
    setIsJsonHashValid(response?.data?.isJsonHashValid);
    setUrl(response?.data?.url);
    setDocumentIsDeleted(response?.data?.documentIsDeleted);
    const splittedFileType = response?.data?.documentName.split(".");
    setFileType(splittedFileType[1]);
    setDocumentSource(response?.data?.documentSource ?? "");
    let contentString = "";
    if (
      response?.data?.analyzedDocumentResult?.pages &&
      response?.data?.enableSelfTraining
    ) {
      contentString = tag.getContentString(
        response?.data?.analyzedDocumentResult?.pages
      );
      tag.setTagContentString(contentString);
    }
    if (response?.data?.enableSelfTraining && contentString) {
      setEnableSelfTraining(true);
    }
    if (response?.data?.analyzedDocumentResult?.document?.tagName) {
      setTagName(response?.data?.analyzedDocumentResult?.document?.tagName);
    }

    let data = response?.data?.analyzedDocumentResult?.pages;
    let data1 = response?.data?.analyzedDocumentResult?.document;
    let postProcessedJson = response?.data?.postProcessedJson?.Document;
    let [fields, metadata] = partition(
      data1?.fields,
      (field) => !field.isMetaDataValue
    );
    // hide DocumentType field
    let fieldsWithoutDocumentType = fields.filter((field) => {
      return field.name != "DocumentType";
    });
    let documentTypeField_ = fields?.find((field) => {
      return field.name == "DocumentType";
    });
    setDocumentTypeField(documentTypeField_);
    let fieldsUnifield = unifyItemFields(fieldsWithoutDocumentType);
    const labels = getLabeColor(fieldsUnifield);
    setLabelColorMap(labels);
    let fields_ = applyColorToLabel(labels, fieldsUnifield);
    let reviewRequiredFields = fields_.filter(
      (field) => field.isFieldReviewRequired
    );
    let documentClassField = fields_?.find(
      (field) => field.name === "DocumentClass"
    );
    if (documentClassField) {
      setDocumentClass(documentClassField.value);
    }
    let fieldsWithNullPolygonStatus = fields_.map((field) => {
      if (Array.isArray(field.itemsFields)) {
        field.itemsFields = field.itemsFields.map((item) => {
          item.item = item.item.map((it) => {
            it.polygonStatus = null;
            return it;
          });
          return item;
        });
      }
      field.polygonStatus = null;
      return field;
    });
    let fieldsWithMaxItemIndex = fieldsWithNullPolygonStatus.map((field) => {
      if (Array.isArray(field.itemsFields)) {
        let maxIndex = field.itemsFields?.length - 1;
        field.maxItemIndex = maxIndex;
      }
      return field;
    });
    let fieldValueMap = {};
    fieldsWithMaxItemIndex?.forEach((field) => {
      fieldValueMap[field.name] = field.value;
    });
    let documentFieldsExpectedValueConfigs_ =
      response?.data?.documentFieldsExpectedValueConfigs?.map((fieldConfig) => {
        fieldConfig.parentFieldConfig.value =
          fieldValueMap?.[fieldConfig.parentFieldConfig?.field];
        return fieldConfig;
      });
    setInitialState((state) => ({
      ...state,
      fields: fieldsWithMaxItemIndex,
      metaData: metadata,
      fileId: fileId,
      status: response?.data?.documentStatus,
      documentFieldsExpectedValues:
        response?.data?.documentFieldsExpectedValues,
      documentFieldsExpectedValueConfigs: documentFieldsExpectedValueConfigs_,
    }));
    setDocumentFieldsExpectedValues(
      response?.data?.documentFieldsExpectedValues
    );
    setDocumentFieldsExpectedValueConfigs(documentFieldsExpectedValueConfigs_);
    setConfidenceArray(reviewRequiredFields);
    let fileExtension_ = response?.data?.documentContentType;
    setTotalPages(data?.length);
    setData(data);
    if (data?.length > 0) {
      setCurrentPageData(data?.[0]);
    }
    let { height: h, width: w } = getDimensions(
      data?.[0]?.height,
      data?.[0]?.width,
      isScreen
    );
    setPageData(
      data?.[0],
      { fields: fieldsWithMaxItemIndex },
      1,
      h,
      w,
      rotation
    );
    setHeight(h);
    setWidth(w);
    setState((state) => ({ ...state, invoiceType: fileExtension_ }));
    let postProcessedJsonMap: any = { general: [] };
    delete postProcessedJson?.["DocumentType"];
    Object.entries(postProcessedJson)?.forEach(([k, item], i) => {
      if (Array.isArray(item)) {
        let list: any[] = [];
        item?.forEach((it, i) => {
          let itemList: any[] = [];
          Object.entries(it)?.forEach(([k, v], ind) => {
            return itemList?.push({
              key: k,
              value: v,
              color:
                labels?.[k] ?? COLOR_LIST[Math.floor(Math.random() * 12)].value,
              ind: i,
            });
          });
          list.push(itemList);
        });
        postProcessedJsonMap[k] = list;
      } else {
        postProcessedJsonMap["general"] = [
          ...postProcessedJsonMap["general"],
          {
            key: k,
            value: item == null ? "" : item,
            color:
              labels?.[k] ?? COLOR_LIST[Math.floor(Math.random() * 12)].value,
          },
        ];
      }
    });
    setPostProcessing(postProcessedJsonMap);
    setIsLoading(false);
  };

  // check if polygon status is updated for atleast one field polygon
  const checkIfPolygonStatusIsUpdated = (fields) => {
    let isUpdated = false;
    fields?.forEach((field) => {
      if (field.polygonStatus != null) {
        isUpdated = true;
      }
      if (Array.isArray(field.itemsFields)) {
        field.itemsFields?.forEach((item) => {
          item.item?.forEach((it) => {
            if (it.polygonStatus != null) {
              isUpdated = true;
            }
          });
        });
      }
    });
    return isUpdated;
  };

  const onClickPrevious = () => {
    if (pageNo > 1) {
      let p = pageNo - 1;
      setPageNo(p);
      setCurrentPageData(data?.[p - 1]);
      if (
        transformComponentRef?.current?.instance?.transformState?.scale &&
        transformComponentRef?.current?.instance?.transformState?.scale > 1
      ) {
        transformComponentRef?.current?.resetTransform();
      }
      let { height: h, width: w } = getDimensions(
        data?.[p - 1]?.height,
        data?.[p - 1]?.width,
        isScreen
      );
      setRotation(0);
      setHeight(h);
      setWidth(w);
      setPageData(data?.[p - 1], ref?.current?.values, p, h, w, 0);
    }
  };

  function getFieldValue(dotNotationPath, sourceObject = ref?.current?.values) {
    let returnData = sourceObject;
    dotNotationPath.split(".").forEach((subPath) => {
      returnData = returnData[subPath] || `Property ${subPath} not found`;
    });
    return returnData;
  }

  const onClick = (area, ind, evt) => {
    var startTime = performance.now();
    evt.preventDefault?.();
    setTimeout(() => {
      setAxis({
        xAxis: evt.pageX,
        yAxis: evt.pageY,
        xAxisDialog: evt.pageX,
        yAxisDialog: evt.pageY,
      });
      setXarrowColor(area.strokeColor);
      setBoundingBox(area?.key || area);
      const virtualAnchor = {
        getBoundingClientRect: () => ({
          top: evt.clientY,
          left: evt.clientX,
          right: evt.clientX,
          bottom: evt.clientY,
          width: 0,
          height: 0,
          x: evt.clientX,
          y: evt.clientY,
          toJSON() {
            return this;
          },
        }),
      };
      setAnchorEl(virtualAnchor);
      let key = area?.key;
      if (key) {
        let keys = key.split(".");
        let value = structuredClone(ref?.current?.values?.[keys[0]]?.[keys[1]]);
        let itemField = structuredClone(getFieldValue(key));
        ref?.current?.setFieldValue("area", {
          ...area,
          ind: ind,
          itemField: itemField,
          field: value,
          value: value,
          itemIndex: +keys?.[3] + 1 || 1,
        });
      } else {
        ref?.current?.setFieldValue("area", {
          ...area,
          ind: ind,
          itemField: null,
          field: {
            name: "un_labelled",
            pageNumber: area.pageNumber,
            value: area.title,
            boundingPolygon: area?.boundingPolygon,
          },
          value: {
            name: "un_labelled",
            value: area.title,
            boundingPolygon: area?.boundingPolygon,
          },
        });
      }
      var endTime = performance.now();
      console.log(
        `Call to doSomething took ${endTime - startTime} milliseconds`
      );
    }, 100);
  };

  const { state: requestState } = reviewResponse;

  const onSubmit = async (values, setSubmitting, tag?, removeTag?) => {
    let response: any;
    if (enableAsyncReviewPage) {
      let documentClass_ = documentClass;
      let field = requestState?.fields?.find(
        (field) => field.name === "DocumentClass"
      );
      if (field) {
        documentClass_ = field.value ?? "";
      }
      let data = requestState;
      let fields = data.fields;
      fields = fields.map((field) => {
        if (Array.isArray(field.itemsFields)) {
          field.itemsFields = field.itemsFields.filter(
            (item) => !(item.isRemoved && item.isNew)
          );
        }
        return field;
      });
      data.fields = fields;

      response = PutAsync(
        `${API_GATEWAY}/api/documentservice/v2/DocumentReview` +
          "/" +
          values?.fileId,
        {
          DocumentId: values.fileId,
          documentClass: documentClass_,
          NewTagName: tag == "" ? null : tag,
          ...data,
        }
      );
    } else {
      if (setModalButtonLoading) setModalButtonLoading(true);
      setSubmitting(true);
      if (removeTagName != "") {
        await DeleteAsync(
          `${API_GATEWAY}/api/extractionservice/selfTraining/tag`,
          {
            tagName: removeTag,
          }
        )
          .then((res) => {
            if (res.status === 404) {
              enqueueSnackbar(translate("Tag not found"), {
                variant: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(translate("Failed to remove tag"), {
              variant: "error",
            });
          });
      }
      let fields = values.fields;
      let metaData = values?.metaData;
      let combinedfields = [documentTypeField, ...fields, ...metaData];
      let body = {
        DocumentId: values.fileId,
        fields: combinedfields,
        NewTagName: tag == "" ? null : tag,
      };
      response = PutAsync(
        `${API_GATEWAY}/api/documentservice/DocumentReview` +
          "/" +
          values?.fileId,
        body
      );
    }
    await response
      .then(async (response) => {
        if (response?.status === 204 || response?.status === 200) {
          snackbar(translate("updated_successfully"), "success", 100);
          DocumentFinished({
            eventName: "Doc Reviewed",
            Id: values?.fileId,
            nameOfDocuments: fileName,
            typeOfDocuments: fileType,
            sourceOfDocuments: documentSource,
            status: true,
            organizationId: COMPANY_ID,
          });
          if (!approvalNotRequired) {
            await handleApprove();
          }
          setSubmitting(false);
          if (handleClose) handleClose();
        } else {
          snackbar(translateErrorMessage(response?.data?.Detail), "error");
          DocumentFinished({
            eventName: "Doc Reviewed",
            Id: values?.fileId,
            status: false,
          });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        snackbar("Something went wrong", "error");
        DocumentFinished({
          eventName: "Doc Reviewed",
          Id: values?.fileId,
          status: false,
        });
        setSubmitting(false);
      });
  };

  const closeDialog = (cancel = false) => {
    setAxis((axis) => ({ ...axis, xAxisDialog: null, yAxisDialog: null }));
    setXarrowColor("");
    setAnchorEl(null);
    ref?.current?.setFieldValue("area", null);
    setTimeout(() => {
      if (!cancel)
        setPageData(
          currentPageData,
          ref?.current?.values,
          pageNo,
          height,
          width,
          rotation
        );
    }, 100);
  };

  const handleScreenBool = () => {
    store.dispatch(setScreen(!isScreen));
    let swapedDimensions = swapDimensions(
      currentPageData?.width,
      currentPageData?.height,
      rotation
    );
    let { width: w, height: h } = getDimensions(
      swapedDimensions?.height,
      swapedDimensions?.width,
      store.getState()?.reviewScreen?.isScreen
    );
    setHeight(h);
    setWidth(w);
    setPageData(currentPageData, ref?.current?.values, pageNo, h, w, rotation);
    setIsScreen(store.getState()?.reviewScreen?.isScreen);
  };

  const [isScreen, setIsScreen] = useState(
    store.getState()?.reviewScreen?.isScreen
  );
  const { avgEvent, generateLine, resetState } = editOptionsProps;
  const { editMode } = toolbarprops;
  const [alreadyLabelledAreas, setAlreadyLabelledAreas] = useState(new Map());

  let areas = [];

  const combineSelectedAreas = () => {
    let line = generateLine(currentPageData?.angle, rotation);
    let index = 0;
    Object.values(alreadyLabelledAreas)?.forEach((area) => {
      let field = getFieldValue(area.key);
      field.boundingPolygon = null;
      field.value = "";
      field.confidence = 0;
      ref?.current?.setFieldValue(area.key, field);
    });
    let box = createOcrBoundingBoxes(
      line,
      index,
      height,
      width,
      currentPageData?.height,
      currentPageData?.width,
      rotation
    );
    box.pageNumber = pageNo;
    let event = avgEvent();
    if (event.pageX !== 0 && event.pageY !== 0) {
      onClick(box, areas.length - 1, event);
    }
    resetState();
    setAlreadyLabelledAreas(new Map());
  };
  const setPageData = async (
    data,
    values,
    pageNo: number,
    height: number,
    width: number,
    rotation: number
  ) => {
    setCurrentPageData(data);
    if (pageNo > 1) {
      let baseString = await fetchBase64String(fileId, pageNo);
      let base64Strings_ = base64Strings;
      base64Strings_[pageNo - 1] = baseString ?? "";
      setBase64String([...base64Strings_]);
    }
    let map = generateAreaMap(values, pageNo, data, height, width, rotation);
    setMAP(map.MAP);
  };
  const [generalTOpen, setGeneralTOpen] = useState(false);

  const handleItemOpenGeneral = (data) => {
    setRows(ref?.current?.values?.fields);
    setGeneralTOpen(true);
  };

  const handleItemCloseGeneral = () => {
    setGeneralTOpen(false);
    setRows([]);
  };
  const [openTagDialog, setOpenTagDialog] = useState(false);
  const { panningProps } = usePanning({ gridRef, height, width });

  if (!readPermissions) {
    return <Navigate to={"/403"} />;
  }
  return (
    <Page title="Review" id={"review-page"}>
      {!isModal && !isLoading && <BreadCrumbs />}
      {accountingPeriod && (
        <AccoutingPeriodDialog
          open={openAccoutingPeriod}
          handleClose={() => setOpenAccountingPeriod(false)}
          accountingPeriod={accountingPeriod}
          writePermissions={writePermissions}
          documentIsDeleted={documentIsDeleted}
        />
      )}
      <SelfTrainingTagDialog
        content={tag.tagContentString}
        open={openTagDialog}
        isPolygonUpdated={isPolygonUpdated}
        setOpenTagDialog={setOpenTagDialog}
        onSave={(tag) => {
          onSubmit(ref?.current?.values, setSubmitting, tag, removeTagName);
        }}
        loading={isSubmitting}
      />
      <GeneralTable
        open={generalTOpen}
        handleClose={handleItemCloseGeneral}
        rows={rows}
        setFieldValue={ref?.current?.setFieldValue}
      />

      <ItemsTableTable
        dispatch={dispatch}
        formState={formState}
        open={open}
        handleItemsClose={handleCloseItemsTable}
        itemsListKey={itemsListKey}
        setFieldValue={ref?.current?.setFieldValue}
        getFieldValue={getFieldValue}
      />

      <AssignWorkflowModal
        isShowModal={isAssignWorkflowModalVisible}
        showModalMethod={handleToggleAssignWorkflowModal}
        documentId={fileId as string}
      />

      <Formik
        initialValues={initialState}
        onSubmit={(values) => onSubmit(values, setSubmitting, tagName)}
        enableReinitialize={true}
        innerRef={ref}
      >
        {({ values, setFieldValue, setValues }) => (
          <Form>
            {postTableItem?.length > 0 && (
              <ItemsTable
                openItems={openPostItems}
                handleItemsClose={handlePostItemsClose}
                label={postTableItem}
                title={postTableTitle}
              />
            )}
            {axis.xAxis && axis.yAxis && boundingBox && (
              <div
                id="end1"
                style={{
                  position: "absolute",
                  top: `${axis.yAxis - 12}px`,
                  left: `${axis.xAxis}px`,
                  transform: `rotate(${rotation}deg)`, // Apply the rotation
                  transformOrigin: "center",
                }}
              ></div>
            )}
            {values.area && (
              <DialogBox
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                closeDialog={closeDialog}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}

            <Box
              sx={{
                px: 2,
              }}
            >
              {alert && !isLoading && (
                <ReviewAlertBox
                  isJsonHashValid={isJsonHashValid}
                  isValidHash={isValidHash}
                  setAlert={setAlert}
                  isDocumentPeriodClosed={accountingPeriod?.isClosed}
                  documentIsDeleted={documentIsDeleted}
                  status={initialState?.status}
                  messages={messages}
                />
              )}
            </Box>

            {!isLoading ? (
              <>
                {!isModal ? (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      px: 2,
                      py: 1,
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="h4" component="div">
                        {translate("Document review")}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="subtitle1" color="text.greyLight">
                          {fileName}
                        </Typography>
                        <SelfTrainingTag
                          {...{
                            tagName,
                            enableSelfTraining,
                            setTagName,
                            removeTagName,
                            setRemoveTagName,
                          }}
                        />
                        <DeletedIndicator isDeleted={documentIsDeleted} />
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <DocumentOptionsButton
                          writePermissions={writePermissions}
                          id={fileId}
                          fileName={fileName}
                          url={url}
                          disablePeriod={accountingPeriod == null}
                          navigation={navigation}
                          setOpenAccountingPeriod={setOpenAccountingPeriod}
                          documentIsDeleted={documentIsDeleted}
                          handleToggleAssignWorkflowModal={
                            handleToggleAssignWorkflowModal
                          }
                        />
                        <span>
                          <Button
                            type="button"
                            size="medium"
                            variant="outlined"
                            disabled={isSubmitting}
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            {translate("Back")}
                          </Button>
                        </span>
                        <span>
                          <LoadingButton
                            type={
                              enableSelfTraining && !tagName
                                ? "button"
                                : "submit"
                            }
                            size="medium"
                            disabled={
                              isSubmitting ||
                              !writePermissions ||
                              documentIsDeleted
                            }
                            variant="contained"
                            loading={isSubmitting}
                            onClick={() => {
                              let check = checkIfPolygonStatusIsUpdated(
                                values?.fields
                              );
                              if (check) {
                                setIsPolygonUpdated(true);
                              }
                              if (enableSelfTraining && !tagName) {
                                setOpenTagDialog(true);
                              }
                            }}
                          >
                            {translate("save")}
                          </LoadingButton>
                        </span>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      p: 3,
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="h4" component="div">
                        {translate("Document review")}
                      </Typography>
                      <Typography variant="h6" component="div">
                        {fileName}
                      </Typography>
                    </Box>
                    <Box>
                      <Box>
                        {approvalNotRequired ? (
                          <LoadingButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            loading={isSubmitting}
                          >
                            {translate("save")}
                          </LoadingButton>
                        ) : (
                          ActionButtons(
                            <ActionButtons isSubmitting={modalButtonLoading} />
                          )
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: { md: "flex", sm: "none" },
                    px: 2,
                    background: "#80808003",
                  }}
                >
                  {xarrowColor &&
                    boundingBox &&
                    currentTab === "0" &&
                    !isScreen && (
                      <Xarrow
                        start={"end1"} //can be react ref
                        end={boundingBox} //or an id
                        zIndex={1}
                        startAnchor={"right"}
                        showHead={false}
                        color={xarrowColor}
                        strokeWidth={2}
                        path="smooth"
                      />
                    )}
                  <TransformWrapper
                    initialScale={1}
                    ref={transformComponentRef}
                    wheel={{
                      disabled: true,
                    }}
                    doubleClick={{
                      mode: "toggle",
                    }}
                    {...panningProps}
                  >
                    {(utils) => (
                      <>
                        <Grid container spacing={1} id={"img-container"}>
                          <ReviewToolbarPanel
                            pages={
                              <>
                                {pageNo} {translate("review_screen.of")}{" "}
                                {totalPages > 0 ? totalPages : 1}
                              </>
                            }
                            combineSelectedAreas={combineSelectedAreas}
                            setAlreadyLabelledAreas={setAlreadyLabelledAreas}
                            editMode={editMode}
                            next={onClickNext}
                            previous={onClickPrevious}
                            {...editOptionsProps}
                            handleScreenBool={handleScreenBool}
                            isScreen={isScreen}
                            {...utils}
                          />
                          <Grid
                            ref={gridRef}
                            id={"img-mapper-page"}
                            item
                            md={isScreen ? 12 : 7}
                            sx={{
                              maxHeight: "75vh",
                              overflow: "auto",
                              width: width,
                              height: height,
                            }}
                            key={"grid_" + (pageNo - 1)}
                          >
                            <TransformComponent
                              wrapperStyle={{
                                pointerEvents: "auto",
                              }}
                              contentClass="scroll-content"
                            >
                              {currentPageData && MAP?.areas?.length > 0 ? (
                                <PageDataView
                                  ref={toolbarprops.imageRef}
                                  data={currentPageData}
                                  base64String={base64Strings[pageNo - 1]}
                                  imageLoading={imageloading[pageNo - 1]}
                                  values={values}
                                  currentPage={pageNo}
                                  onMouseEnter={onMouseEnter}
                                  onMouseLeave={onMouseLeave}
                                  onClick={onClick}
                                  invoiceType={state.invoiceType}
                                  editOptionsProps={editOptionsProps}
                                  toolbarprops={toolbarprops}
                                  alreadyLabelledAreas={alreadyLabelledAreas}
                                  height={height}
                                  width={width}
                                  MAP={MAP}
                                  callback={callback}
                                  setCallback={setCallback}
                                />
                              ) : (
                                <XMLDataView values={values} />
                              )}
                            </TransformComponent>
                          </Grid>
                          {!isScreen && (
                            <Grid
                              id={"scrollToScreen"}
                              item
                              md={5}
                              sx={{
                                maxHeight: "75vh",
                                p: 2,
                                width: { md: "150px" },
                                overflow: "auto",
                              }}
                            >
                              <>
                                <Box>
                                  <Box
                                    component={"div"}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {confidenceArray?.length > 0 ? (
                                      <Typography variant={"h6"}>
                                        {translate(
                                          "review_screen.review_required"
                                        )}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                  {confidenceArray?.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <div key={"confidence-" + index}>
                                          {item.isFieldReviewRequired && (
                                            <LowConfidence
                                              title={item.name}
                                              item={item}
                                              setFieldValue={setFieldValue}
                                              getFieldValue={getFieldValue}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Box>
                                <Tabs
                                  scrollButtons="auto"
                                  value={currentTab}
                                  onChange={onChangeTab}
                                  sx={{
                                    px: 1,
                                    height: "10px",
                                    bgcolor: "background.paper",
                                  }}
                                >
                                  {TABS.map((tab) => (
                                    <Tab
                                      key={tab.value}
                                      value={tab.value}
                                      label={tab.label}
                                      disableRipple
                                    />
                                  ))}
                                </Tabs>
                                {currentTab == "0" ? (
                                  <PreProcessed
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    fields={values.fields}
                                    title={title}
                                    handleMouseScroll={handleMouseScroll}
                                    removeArrowAxis={removeArrowAxis}
                                    handleItemOpen={handleOpenItemsTable}
                                    handleItemOpenGeneral={
                                      handleItemOpenGeneral
                                    }
                                    tableRefs={tableRefs}
                                    generateMap={() => {
                                      let map = generateAreaMap(
                                        values,
                                        pageNo,
                                        data[pageNo - 1],
                                        height,
                                        width,
                                        rotation
                                      );
                                      setMAP(map.MAP);
                                    }}
                                  />
                                ) : (
                                  <PostProcessing
                                    postProcessing={postProcessing}
                                    handleItemOpen={handlePostItemOpen}
                                    title={title}
                                  />
                                )}
                              </>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </TransformWrapper>
                </Box>
              </>
            ) : (
              <ReviewPageSkeleton />
            )}
          </Form>
        )}
      </Formik>
    </Page>
  );
};
export default FormValidation;
