// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  authentication_screen: {
    signin_title: `Sign in to Dost`,
    signin_text: `Enter your details below.`,
    email_address: `Email Address`,
    password: `Password`,
    remember_me: `Remember me`,
    forgot_password: `Forgot your password?`,
    signin_header_text: `Don’t have an account?`,
    get_started: `Get started`,
    login_btn: `Log in`,
    forgot_password_title: `Forgot your password?`,
    forgot_password_text: `Please enter the email address associated with`,
    forgot_password_text_second: `your account, and we'll email you a link to reset your password.`,
    reset_password: `Reset Password`,
    back_txt: `Back`,
    continue_txt: `Continue`,
    get_started_title: `Get started absolutely free.`,
    get_started_text: `Free forever. No credit card needed.`,
    pre_reg_text: `By signing up, I agree to Dost`,
    pre_reg_text_one: `and`,
    terms_text: `Terms of Service`,
    privacy_policy: `Privacy Policy`,
    pre_reg_header_text: `Already have an account?`,
    pre_reg_btn: `Try for free?`,
    login_txt: `Login`,
    register_title: `Get started absolutely free.`,
    first_name: `First name`,
    last_name: `Last name`,
    phone_number: `Phone number`,
    organization: `Your organization`,
    role: `Which role describes you best?*`,
    sector: `In which sector do you operate?*`,
    register_btn: `Create my account`,
    pre_verify_title: `Please check your Email!`,
    pre_verify_text_one: `We've sent you a  confirmation email at`,
    pre_verify_text_two: `Please check your spam folder if it is not in your inbox`,
    pre_verify_email_text: `Didn’t receive any Email? Please click on Resend Email`,
    resend_email_txt: `Resend Email`,
    login_with_google: `Login with Google`,
    login_with_outlook: `Login with Outlook`,
    something_went_wrong: `Something went wrong, please try again`,
    plan: `Subscribed Plan`,
    account_created: `Account Created Successfully`,
    "Please, use a professional email address to create a Dost account": `Please, use a professional email address to create a Dost account`,
    receivedInvoices: `How many invoices do you receive each month?`,
    signup_with_google: `Sign up with Google`,
    signup_with_outlook: `Sign up with Outlook`,
    use_professional_account: `Please, use a professional email address to create a Dost account`,
    alreadyExistError: `User already exists`,
    email_required: `Email is required`,
    email_invalid: `Email must be a valid email address`,
    or_signup_with: `or signup with`,
    or_login_with: `OR`,
    country_name: `Company country`,
    first_name_schema: `Only alphabets are allowed for this field `,
    last_name_schema: `Only alphabets are allowed for this field `,
    password_schema: `Password must contain at least 8 characters, and contain an upper case letter, lower case letter, number, and symbol`,
    "Invalid Email or Password": `Invalid Email or Password`,
    "Username or password did not match": `Username or password did not match`,
    dummy_email: `name@company.com`,
    enter_password: `Enter Password`,
    email_helper_txt: `Must be a professional email`,
    password_helper_txt: `Must be at least 8 characters`,
    reset_password_title: `Reset password`,
    reset_password_text: `Please enter your new password`,
  },
  dashboard: {
    dashboard: `Dashboard`,
    Timesaved: `Time saved`,
    Moneysaved: `Money saved`,
    number_of_docs_processed: `Number of docs processed`,
    data_processed: `Data processed`,
    processed: `Processed`,
    needs_review: `Needs Review`,
    error: `Error`,
    document_processed: `Document Processed`,
    recent_documents: `Recent Documents`,
    top_vendors: `Top 5 Vendors`,
    document_name: `Document Name`,
    processed_on: `Processed on`,
    status: `Status`,
    your_plan: "Your plan",
    view_all: "view all",
    upgrade: `Upgrade`,
    document_types: `Document Types`,
    total_documents_count: `Total documents count`,
  },
  finance_screen: {
    email_text: `Your own dedicated email to send the documents`,
    docs_processed: `Processed Documents`,
    email_csv_export: `Email CSV Export`,
    selected_files: `Selected file(s)`,
    remove_all: `Remove all`,
    upload_files: `Upload files`,
    duplicate_files_are_not_allowed: `Duplicate Files are not Allowed`,
    files_cannot_be_more_than_10: `Files cannot be more than 10`,
    document_uploaded_successfully: `Document Uploaded Successfully`,
    extract_now: `Extract now`,
    all: `All`,
    Invoice: `Invoices`,
    PurchaseOrder: `Purchase Orders`,
    credit_memo: `Credit Memo`,
    delivery_note: `Delivery Note`,
    reciepts: `Receipts`,
    processed_on: `Processed on`,
    DeliveryNote: `Delivery Note`,
    Reciepts: `Receipts`,
    filename: `File Name`,
    processed_at: `Processed At`,
    output: `Output`,
    status: `Status`,
    creeated_in_erp: `Created in ERP`,
    no_data: `No data`,
    dense: `Dense`,
    rows_per_page: `Rows per page:`,
    retry_button: `Retry`,
    yes_button: `Yes`,
    no_button: `No`,
    start_date: `Start Date`,
    end_date: `End Date`,
    search: `Search`,
    document_upload_unsuccessful: `Document Upload Failed`,
    "Document not supported": `Document not supported`,
    "Number of pages exceed": "Number of pages exceeded",
    "Limit Exceed": `Document Limit Exceeded`,
    document_name: `Document Name`,
    SimpleNote: `Simple Note`,
    WorkContract: `Work Contract`,
    Payroll: `Payroll`,
    DNI: `DNI`,
    Passport: `Passport`,
    NIE: `NIE`,
    SimulatedInvoice: `Simulated Invoice`,
    output_data: `Output data`,
    CreditNote: `Credit Note`,
    document_delete_unsuccessful: `Document Delete Failed`,
    document_deleted_successfully: `Document(s) deleted successfully`,
    documentdelete_document_extraction_inprocess: `Document extraction in process`,
    documentdelete_document_already_deleted: `Document is already deleted`,
    documentdelete_disable_delete_integrated_document: `Disable delete integrated document`,
    documentdelete_document_payment_inprocess: `Document payments is in process`,
    documentdelete_document_paid: `Document is paid`,
  },
  integrations_screen: {
    integrations: `Integrations`,
    dashboard_text: `Dashboard`,
    integration_text: `Integration`,
    new_integration_button_text: `Add a new Integration`,
    add_integration: `Add an Integration`,
    all: `All`,
    data_sources: `Data sources`,
    data_sinks: `Data sinks`,
    erp: `erps`,
    paltform: `plataform`,
    type: `type`,
    status: `status`,
    plan: `plan`,
    dense: `Dense`,
    rows_per_page: `Rows per page:`,
    upgrade_modal_title: `Please upgrade your plan by clicking below`,
    upgrade_btn: `Upgrade`,
    integrations_connected: `Integrations connected`,
    integration_not_connected: `You don’t have any integration connected yet`,
    erp_connected: `ERP connected`,
    disconnect: `Disconnect`,
    data_source_connected: `Data source connected`,
    available_integrations: `Integrations available to connect`,
    ERP: `ERP`,
    connect: `Connect`,
    payment_gateways: `Payment Gateways`,
  },
  new_integrations_screen: {
    integrations: `Integrations`,
    dashboard_text: `Dashboard`,
    integration_text: `Integration`,
    list: `List`,
    business_system: `Business System`,
    data_sources: "Data sources",
    data_sinks: `Data sinks`,
    new_integration_button_text: `New Integration`,
    tooltip_dialogbox_text:
      "Click on one of the cards below, to connect to your business system",
  },
  review_screen: {
    meta_data: `Meta Data`,
    title: `Title`,
    value: `Value`,
    extraction: `Extraction`,
    update: `Update`,
    add: `Add`,
    add_meta_data: `Add Meta Data`,
    of: `of`,
    next: `Next`,
    finish: `Finish`,
    post_processing: `Post Processing`,
    new_review_alert: "To go back to the previous version",
    old_review_alert_1: " A new version is available, check it out",
    old_review_alert_2:
      "Please note: This version is not compatible with older documents",
    now: `now`,
    click_here: `Click here`,
    email_text: `Your own dedicated email to send the documents`,
    docs_reviewed: `Review Documents`,
    email_csv_export: `Email CSV Export`,
    extract_now: `Extract now`,
    all: `All`,
    invoices: `Invoices`,
    purchase_orders: `Purchase Orders`,
    credit_memo: `Credit Memo`,
    delivery_note: `Delivery Note`,
    reciepts: `Receipts`,
    filename: `File Name`,
    processed_at: `Processed At`,
    output: `Output`,
    status: `Status`,
    creeated_in_erp: `Created in ERP`,
    no_review: `No review needed`,
    dense: `Dense`,
    rows_per_page: `Rows per page:`,
    add_button: `Add`,
    finish_button: `Finish`,
    review_required: `Review Required`,
    back_button: `Back`,
    tooltip_dialogbox_textone:
      "After sending the invoice click on it to review.",
    tooltip_dialogbox_texttwo: "Click on the file below to start reviewing it.",
    refresh_btn: `Refresh`,
    dialog_box: {
      label: "Label",
      value: "Value",
      row_number: "Row number",
      row: "Row",
      field_required: "Field is required",
      itemindex_required: "Row number is required",
      item_required: "Field is required",
      save: "Save",
      remove: "Remove",
    },
    review_fields: {
      DocumentCategory: "Document Category",
      Discrepancies: `Discrepancies`,
      AccountClass: `Account Class`,
      DiscrepancyDetected: "Discrepancy Detected",
      DocumentGuid: "Document Guid",
      taxes: `Taxes`,
      InvoiceID: `Invoice ID`,
      InvoiceGuid: `Invoice Guid`,
      CustomerCompanyName: `Customer Company Name`,
      CustomerCompanyID: `Customer Company ID`,
      CustomerCompanyAddress: `Customer Company Address`,
      VendorCompanyName: `Vendor Company Name`,
      VendorCompanyID: `Vendor Company ID`,
      VendorCompanyAddress: `Vendor Company Address`,
      ShipmentAddress: `Shipment Address`,
      BillingCustomerCompanyName: `Billing Customer Company Name`,
      BillingCustomerCompanyID: `Billing Customer Company ID`,
      BillingCustomerAddress: `Billing Customer Address`,
      PoNumber: `PO Number`,
      InvoiceDate: `Invoice Date`,
      DueDate: `Due Date`,
      CurrentDate: `Current Date`,
      TotalWithoutTax: `Total Without Tax`,
      TotalTax: `Total Tax`,
      TotalAmount: `Total Amount`,
      CustomerEmail: `Customer Email`,
      VendorEmail: `Vendor Email`,
      PhoneNumber: `Phone Number`,
      ContactPerson: `Contact Person`,
      DeliveryNote: `Delivery Note`,
      ConstructionCode: `Construction Code`,
      ProviderCode: `Provider Code`,
      ReferenceCode: `Reference Code`,
      Description: `Description`,
      Unit: `Unit`,
      UnitPrice: `Unit Price`,
      Quantity: `Quantity`,
      ValueAddedTaxPercentage: `Value Added Tax Percentage`,
      ValueAddedTax: `Value Added Tax`,
      DtoPercentage: `DTO Percentage`,
      Dto: `Discount`,
      DiscountPercentage: `Discount Percentage`,
      Discount: `Discount`,
      CommissionPercentage: `Commission Percentage`,
      Commission: `Commission`,
      SubTotalAmount: `SubTotal Amount`,
      Amount: `Amount`,
      BaseAmount: `BaseAmount`,
      CustomerAddressRecipient: `Customer Address Recipient`,
      PaymentTerm: `PaymentTerm`,
      "Base-Amount": `Base Amount`,
      VAT: `VAT`,
      "VAT%": `VAT%`,
      BillingAddressRecipient: "Billing Address Recipient",
      TotalwithoutTax: `Total Without Tax`,
      ProductCode: `Product Code`,
      Currency: `Currency `,
      DocumentType: `Document Type`,
      "Reference/Code": `Reference Code`,
      PONumber: `PO Number`,
      Total_after_Tax: `Total After Tax`,
      VendorAddressRecipient: `Vendor Address Recipient`,
      "VAT %": `VAT %`,
      ShippingCost: `Shipping Cost`,
      WithholdingTax: `Withholding Tax`,
      general: `General`,
      Items: `Items`,
      PaymentDetails: `Payment Details`,
      "Items-table-general": `Items Table General`,
      "taxes-table": `Taxes Table`,
      "ShippingCost-table": `Shipping Cost Table`,
      UtilityTaxes: `Utility Taxes`,
      VendorId: `Vendor Id`,
      TaxType: `Tax Type`,
      TaxValue: `Tax Value`,
      TaxValueType: `Tax Value Type`,
      BaseAmountFieldName: `Base Amount Field Name`,
      TaxRate: `Tax Rate`,
      VATPercentage: `VAT Percentage`,
      SubTotal: `Sub Total`,
      un_labelled: `Unlabelled`,
      DocumentId: `Document Id`,
      Batch: `Batch`,
      SizeReference: `Size Reference`,
      Wholesale: `Wholesale`,
      RRP: `RRP`,
      TotalWholesale: `Total Wholesale`,
      TotalRRP: `Total RRP`,
      QuantitySize: `Quantity Size`,
      VendorCulture: `Vendor Culture`,
      DocumentStatus: `Document Status`,
      DocumentURL: `Document URL`,
      ProcessedDate: `Processed Date`,
      CreationDate: `Creation Date`,
      LastModifiedDate: `Last Modified Date`,
      DocumentHash: `Document Hash`,
      DocumentSource: `Document Source`,
      FileName: `File Name`,
      UserId: `User Id`,
      DocumentClass: `Document Class`,
      InternalCompanyCode: `Internal Company Code`,
      DocumentNumber: `Document Number`,
      DocumentDate: `Document Date`,
      actions: `Actions`,
      TariffCode: `Tariff Code`,
      OriginCountry: `Origin Country`,
      Weight: `Weight`,
      ContactEmail: `Contact Email`,
      DeliveryAddress: `Delivery Address`,
      PaymentMethod: `Payment Method`,
      TotalDiscount: `Total Discount`,
      DeliveryDate: `Delivery Date`,
      ShippingAddress: `Shipping Address`,
      PaymetMethod: `Payment Method`,
      ShippingMethod: `Shipping Method`,
      Taxes: `Taxes`,
      TotalAfterTax: `Total After Tax`,
      PaymentTerms: `Payment Terms`,
      Accounts: `Accounts`,
      AmountPercentage: `Amount Percentage`,
      GLAccount: `GL Account`,
      CostCenter: `Cost Center`,
      RefCriteria: `Ref Criteria`,
    },
  },
  coming_soon_screen: {
    coming_soon: "Coming Soon!",
    coming_soon_text: "We are currently working hard on this page! ",
  },
  support_screen: {
    subject: `Subject`,
    text: `Type a message`,
    button_text: `send`,
  },
  status: {
    in_process: `In process`,
    processed: `Processed`,
    failed: `Failed`,
    error: `Error`,
    need_review: `Need Review`,
    reviewed: `Reviewed`,
    duplicate: `Duplicate`,
    connected: `Connected`,
    not_connected: `Not connected`,
    success: `Success`,
    plan: `free`,
  },
  "approval not required": `Approval not required`,
  "Approval required": `Approval required`,
  account_screen: {
    "Company ID": `Company ID`,
    registrationError: `Registration ID is required`,
    registrationSpecialChrError: `Registration ID should not contain special characters`,
    registrationSpaceError: `Registration ID should not contain spaces`,
    registrationId: `Registration ID`,
    firstName: `First name`,
    lastName: `Last name`,
    email: `Email`,
    phoneNumber: `Phone Number`,
    organization: `Organization`,
    other: `Other`,
    account_name: `Account`,
    users: `Users`,
    account_settings: `Account Settings`,
    general: `general`,
    "change password": "change password",
    name: `Name`,
    phone_number: `Phone Number`,
    country: `Country`,
    city: `City`,
    about: `About`,
    email_address: `Email Address`,
    address: `Address`,
    state: `State/Region`,
    zipcode: `ZIP/CODE`,
    save_changes: `Save Changes`,
    name_validation: `Name is required`,
    phone_number_validation: `Phone Number is required`,
    country_validation: `Country is required`,
    city_validation: `City is required`,
    about_validation: `About is required`,
    email_address_validation: `Email Address is required`,
    address_validation: `Address is required`,
    state_validation: `State/Region is required`,
    zipcode_validation: `ZIP/CODE is required`,
    firstNameError: "First Name is required",
    lastNameError: "Last Name is required",
    emailError: "Email is required",
    phoneNumberError: "Phone Number is required",
    passwordError: "Password is required",
    organizationError: "Organization is required",
    countryError: "Country is required",
    otherError: "Other is required",
    answerError: "Answer is required",
    otherAnswerError: "Other Answer is required",
    lettersOnlyError: "Only letters are allowed",
    roleInOrganization: "Which role describes you best?",
    sectorInOrganization: "In which sector do you operate?",
    otherSectorInOrganization: "Other Sector in Organization",
    otherRoleInOrganization: "Other Role in Organization",
    CFO: "CFO",
    "Financial Manager": "Financial Manager",
    Accountant: "Accountant",
    Freelance: "Freelance",
    Other: "Other",
    Construction: "Construction",
    Shipping: "Shipping",
    Food: "Food",
    "Not sure": "Not sure",
    "organization setting": "organization setting",
    money_saved: `Money Saved`,
    time_saved: `Time Saved in minutes`,
    save: `Save Changes`,
    currency: `Currency`,
    "One or more validation errors occurred.": `One or more validation errors occurred.`,
    image_error: `Logo height and width should be in 1200 pixels.`,
    hide_email: `Hide Email`,
    hide: `Hide`,
    show: `Show`,
    something_went_wrong: `Something went wrong`,
    updated_successfully: `Updated Successfully`,
    update_fail: `Update failed!`,
    upload_logo: `Upload organization Logo`,
    roleError: `Role is required`,
    sectorError: `Sector is required`,
    invoicesError: `Invoices received is required`,
    webhook: `Webhook`,
    notificationEmail: `Notification email`,
    notification_email_message_0: `If this is set then all notifications will be sent on this email`,
    notification_email_message_1: `(by default all email notifications goes on account's email)`,
  },
  password_screen: {
    old_password: `Old Password`,
    new_password: `New Password`,
    confirm_password: `Confirm New Password`,
  },
  low_confidence_modal: {
    please_review_the_processed_value: "Please review the processed value",
    extracted_value: "Extracted Value",
    new_value: "New Value",
    save: "Save",
    cancel: "Cancel",
  },
  meta_data_modal: {
    meta_data: "META DATA",
    title: "Title",
    value: "Value",
  },
  holded_modal: {
    title_text: `Connect Dost with your HOLDED account`,
    paragraph_text_one: `Please go to the link:`,
    paragraph_text_two: `and copy/paste your Holded key below:`,
    apikey_text: `API Key`,
    button_text_connect: `Connect`,
    button_text_disconnect: `Disconnect`,
    validation: `API is required`,
  },
  sapb_one_modal: {
    title_text: `Connect Dost with your SAPB1 service layer`,
    details_text: `Enter the details below:`,
    server_address: `Server Address`,
    password_text: `Password`,
    port: `Port`,
    user_name: `User Name`,
    company_db: `Company DB`,
    uploading_mapping: `Uploading Mapping`,
    select_file: `Drop or Select file`,
    select_file_paragraph: `Drop files here or click`,
    select_file_paragraph_second: `through your machine`,
    browse: `browse`,
    button_text: `Connect`,
    button_text_disconnect: `Disconnect`,
    server_validation: `Server Address is required`,
    port_validation: `Port is required`,
    name_validation: `User Name is required`,
    password_validation: `Password is required`,
    db_validation: `Company DB is required`,
    file_validation: `CSV File is required`,
  },
  delsol_modal: {
    delsol_title: `Connect Dost with your DELSOL account`,
    delsol_subtitle: `Please provide the details below to setup your 
       delsol integration`,
    codigo_fabrica: `Factory code`,
    codigo_cliente: `Client code`,
    base_datos: `Database`,
    password: `Password`,
    type_codigo_fabrica: `Type Factory code`,
    type_codigo_cliente: `Type Customer code`,
    type_codigo_base_datos: `Type Database`,
    type_password: `Type Password`,
    button_text: `Connect`,
    button_text_disconnect: `Disconnect`,
    codigo_fabrica_validation: `Factory code is required`,
    codigo_cliente_validation: `Client code is required`,
    codigo_datos_validation: `Code is required`,
    password_validation: `Password is required`,
  },
  onedrive_modal: {
    onedrive_text: `Connect Dost with your OneDrive account`,
    button_text: `Connect`,
    button_text_disconnect: `Disconnect`,
    open_folder: `Open Folder`,
  },
  gdrive_modal: {
    heading: `Connect Dost with your Googl Drive account`,
    connect: `Connect`,
    disconnect: `Disconnect`,
    open_folder: `Open Folder`,
  },
  coming_soon_modal: {
    coming_soon: "Coming Soon!",
  },
  cardoner_modal: {
    cardoner_title: `Connect Dost with your Web Service`,
    cardoner_text: `Please provide the details below to send data to your webservice`,
    address: `Address`,
    port: "Port",
    user_name: "User Name",
    password: "Password",
    button_text: `Connect`,
    button_text_disconnect: `Disconnect`,
    button_text_batch: `Batch`,
    address_validation: `Address is required`,
    port_validation: `Port is required`,
    password_validation: `Password is required`,
    user_name_validation: "User Name is required",
  },
  ftp_output: {
    FtpHost: "Host",
    FtpPort: "Port",
    FtpUserId: "User Id",
    FtpPassword: "Password",
    FtpPath: "Path",
    button_text_connect: "Connect",
    button_text_disconnect: "Disconnect",
    companyId: "Company Id",
    apiPublisher: "Api Publisher",
    apiGroup: "Api Group",
    apiVersion: "Api Version",
    ftp_output_text: `Please provide the details below to connect to your FTP server`,
    tenant: "Tenant",
    clientId: "Client Id",
    clientSecret: "Client Secret",
    environmentName: "Environment Name",
    validation: {
      FtpHost: "Host is required",
      FtpPort: "Port is required",
      FtpUserId: "User Id is required",
      FtpPassword: "Password is required",
      FtpPath: "Path is required",
      required: "This field is required",
    },
  },
  notification_modal: {
    success_message: "Successfully connected",
    unsuccess_message: "Successfully disconnected",
    in_process: "In process",
  },
  password_validation: {
    old_password: `Old Password is required`,
    new_password: `Password must contain at least 8 characters, and contain an upper case letter, lower case letter, number, and symbol`,
    confirm_password: `Passwords must match`,
    confirm_password_required: `Confirm password is required`,
    confirm_new_password: `Confirm New Password`,
  },
  sidebar: {
    general: `general`,
    home: `Home`,
    documents: `documents`,
  },
  upgrade_screen: {
    title: `Upgrade your plan`,
    sub_title_one: `Your plan section`,
    sub_title_two: `Add-Ons`,
    starter_text: `Starter`,
    pro_text: `PRO`,
    enterprise_text: `Enterprise`,
    basic_plan_text: `Add to Basic plan`,
    pro_plan_text: `Add to Starter/Pro plan`,
    month_text: `month`,
    free_text: `Free`,
    sources_text: `Sources: OneDrive and Google Drive`,
    scrapping_text: `SCRAPPING - invoices`,
    erp_text: `ERP Integrations API`,
    documents_text: `200 documents/month`,
    support_text: `Support`,
    users_text: `users`,
    starter_btn_text: `Upgrade to Starter`,
    pro_starter_text: "STARTER",
    ai_text: `AI CUSTOM TRAINING`,
    approval_text: `3 WF APPROVALS`,
    fraud_text: `FRAUD DETECTION`,
    validation_text: `VALIDATION`,
    invoices_text: `500 invoices/month`,
    pro_btn_text: `Upgrade To Pro`,
    dedicated_text: `Dedicated Project Manager`,
    enterprise_btn_text: `Contact Us`,
    customer_success_text: `Dedicated Customer Success Manager`,
    dost_api_text: `DOST API connection (3€/month)`,
    documents_text_addon: `50 documents (5€/month)`,
    wf_approvals: `1 WF APPROVALS (29€/month)`,
    values_validation: `VALUES VALIDATION`,
    already_subscribed: `Subscribed`,
    buy_now_btn: `Buy Now`,
    subscription_btn: `Manage Subscription`,
    plan_txt: `Your plan includes`,
    users_txt: `Users`,
    invoices_txt: `Invoices`,
  },
  checkout_screen: {
    checkout_btn_txt: `Checkout`,
    empty_cart: `Empty Cart`,
    clear_cart: `Clear cart succesfully`,
    shop_btn: `Continue Shopping`,
    cart_txt: `Cart`,
    empty_cart_txt: `Cart is empty`,
    cart_title: `Look like you have no items in your shopping cart.`,
    cart_description: `Look like you have no items in your shopping cart.`,
    checkout_txt: `Checkout`,
    dashboard_txt: `Dashboard`,
    upgrade_txt: `Upgrade Plan`,
    summary: `Summary`,
    subscription: `Subscription`,
    add_ons: `Add ons`,
    product_text: `Product`,
    quantity_txt: `Quantity`,
    price_txt: `Price`,
    delete_txt: "Are you sure you want to delete selected Plan from cart ?",
    yes_btn: "Yes",
    no_btn: "No",
  },
  Bumper: {
    heading: "Complete the on-boarding steps",
    subheading: "Complete these steps to start getting the most out of Dost",
    titleStep1: "Send your first document",
    titleStep2: "Review a document",
    titleStep3: "Connect your ERP",
    discStep1:
      "Send your first document to teach your Dost Digital Brain what kind of documents you will send it to process automatically.",
    discStep2:
      "Go to the Review page to take a look at the values that have not been processed correctly. Make sure to complete the pending information in order to send it to your ERP.",
    discStep3:
      "Connect your ERP or data sources to get the most out of process automation. From start to finish",
    sendBtn1: "Send document",
    sendBtn2: "Go to review",
    sendBtn3: "Go to integerations",
    skipBtn: "Skip button",
    Markascompleted: "Mark as completed",
    mainHeading: "Start here to get the best out of Dost",
    mainPara1: "You have completed",
    mainParaOf: "of",
    mainPara2: "steps to finish the basic configuration.",
    progressLabel: "Current progress",
    financeCardHead: "Send documents to your portal",
    financeCardLine1: "Send to document to this Email address.",
    financeCardLine2:
      "Click on “Extract Now” after few seconds to see the document in the portal.",
    financeCardLine3:
      "You can also send and forget and dost will extract all of your documents every five minutes.",
  },
  supplier_screen: {
    impersonate_supplier: "Impersonate supplier",
    update_supplier_form: "Update supplier",
    reset_message:
      "Are you sure you want to update this supplier? updating supplier will reset the onboarding process",
    supplierName: "Supplier name",
    view_supplier: "View supplier",
    supplierEmail: "Supplier email",
    supplierUrl: "Supplier portal URL",
    createSupplier: "Create supplier",
    created: "Created supplier successfully",
    notCreated: "Error creating the supplier",
    cancel_button: "Cancel",
    dense: "Dense ",
    Action: "Action",
    edit_supplier: `Edit`,
    delete_supplier: `Delete`,
    delete_supplier_dialog: `Delete supplier`,
    updated: `Updated supplier successfully`,
    deleted: `Supplier deleted successfully`,
    reset_supplier: `Reset supplier`,
    reset_approval: `Are you sure you want to reset onboarding of this supplier?`,
    reset_successful: `Supplier onboarding reset successfully`,
    reset_onboarding: `Reset onboarding`,
    reset_supplier_onboarding: `Reset supplier onboarding`,
  },
  users_screen: {
    users: "Users",
    invite_users: "Invite Users",
    send_invite: "Send Invite",
    email_address: "Email Address",
    cancel_button: "Cancel",
    invite_sent_successfully: "Invite sent successfully",
    something_went_wrong: `Something went wrong`,
    dense: `Dense`,
    update_profile: `Please update profile to send an invite.`,
    user_id: `User ID`,
    role: `Role`,
  },

  interval_error: `Interval cannot be less than 30 minutes`,
  interval_required: `Interval is required`,
  interval_integer_not_allowed: `Interval must be a whole number`,

  configuration_modal: {
    add_custom_field: `Add Custom Field`,
    cancel: `Cancel`,
    save: `Save`,
    add_vendor_config: `Add Vendor Configuration`,
    vendor_id: `Vendor ID`,
    date_format: `Date Format`,
    add: `Add`,
    update: `Update`,
    duplicate: `Duplicate`,
    add_customer_config: "Add Customer Configuration",
    customer_id: "Customer ID",
  },

  app: `app`,
  user: `User`,
  list: `list`,
  edit: `Edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `Profile`,
  account: `account`,
  product: `product`,
  invoice: `Invoice`,
  details: `Details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `Analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `Description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  home: `Home`,
  settings: `Settings`,
  logout: `Logout`,
  admin: `Admin`,
  documents: `Documents`,
  integrations: `Integrations`,
  review: `Review`,
  data: `Data`,
  support: `Support`,
  finance: `Finance`,
  workflow: "Workflow",
  operations: `Operations`,
  upgrade: `Upgrade`,
  "data analytics": `Data Analytics`,
  "resource saved": `Resource Saved`,
  suppliers: `Suppliers`,
  configuration: `Configuration`,
  users: `Users`,
  integration: `Integration`,
  email: `Email`,
  ftp: `FTP`,
  email_receive_type: `Receive Type`,
  email_receive_type_required: `Type of Email Received is required`,
  host_required: `Host is required`,
  port_required: `Port is required`,
  port_validation: `Port must only contain number`,
  username_required: `Username is required`,
  password_required: `Password is required`,
  path_required: `Path is required`,
  host: `Host`,
  password: `Password`,
  path: `Path`,
  port: `Port`,
  userName: `User name`,
  discrepancies: `Discrepancies`,
  view_discrepancies: `View discrepancies`,
  select_document: `Please select one document`,
  document_name: `Document Name`,
  processed_on: `Processed On`,
  document_id: `Document ID`,
  messages: `Messages`,
  delivery_notes: `Delivery Notes`,
  delivery_note: `Delivery Note`,
  delivery_note_number: `Delivery Note Number`,
  vendor_name: `Vendor Name`,
  vendorId: `Vendor ID`,
  issue_date: `Issue Date`,
  totalAmount: `Total Amount`,
  reference_code: `Reference Code`,
  quantity: `Quantity`,
  no_discrepancies: `No Discrepancies`,
  invoices: `Invoices`,
  close: `Close`,
  hello: `Hello`,
  select_language: `Select language`,
  login_tag_line: `Collect and process your invoices automatically ensuring error-free execution`,
  delivery_note_items: `Delivery Note Items`,
  invoice_items: `Invoice Items`,
  Field: `Field`,
  "General Table": `General Table`,
  // Discrepancy Messages
  po_number_not_found_in_any_items_general: `PO number not found in any items`,
  delivery_note_not_found_in_any_items_general: `Delivery note not found in any items.`,
  no_item_found_in_invoice_general: `No item(s) found in Invoice.`,
  delivery_note_not_found_general: `Delivery note not found`,
  invoice_item_count_miss_match_general: `Invoice item(s) count miss match.`,
  invoice_item_not_found_in_deliverynote_general: `Invoice item not found in delivery note`,
  invoice_item_quantity_greaterthan_general: `Invoice item quantity greater than delivery note quantity`,
  delivery_notes_related_to_general: `Delivery Notes related to`,
  documents_related_to_general: `Documents related to`,
  delivery_note_not_attached_general: `Delivery note not attached`,
  document_number: `Document number`,
  invoice_data: `Invoice Data`,
  items_with_conflicts: `Items with conflict`,
  // Invoice & Delivery Note Discrepancy
  item_descriptionandcode_not_attached: `Item description and reference code not attached in Invoice # {{0}} and Delivery note #{{1}}`,
  item_quantity_is_zero: `The quantity of "{{0}}" in Delivery Note #{{1}} for Invoice #{{2}} is zero.`,
  no_item_found_in_invoice: `No item found in Invoice #{{0}}`,
  delivery_note_not_found: `Delivery Note #{{0}}, referenced in Invoice #{{1}}, is missing.`,
  delivery_note_not_found_in_any_items: `Delivery Note not found in any items referenced in Invoice #{{0}}`,
  invoice_item_count_miss_match: `The item count in Invoice #{{1}} does not match the item count in Delivery Note #{{0}}.`,
  delivery_note_not_attached: `Delivery Note is not attached to Invoice #{{0}}.`,
  delivery_note_item_descriptionandcode_not_attached: `Item descriptions and codes are not attached to Invoice #{{0}} and Delivery Note #{{1}}.`,
  invoice_item_not_found_in_deliverynote: `The item with description "{{0}}" in Invoice #{{2}} is not found in Delivery Note #{{1}}`,
  delivery_note_item_quantity_is_zero: `The quantity of "{{0}}" in Delivery Note #{{1}} for Invoice #{{2}} is zero.`,
  invoice_item_quantity_greaterthan: `The quantity of item "{{0}}" on the delivery note #{{1}} is {{2}} units, which does not match invoice {{3}} where it is stated as {{4}} units.`,
  invoice_item_quantity_lessthan: `The quantity of item "{{0}}" on the delivery note #{{1}} is {{2}} units, which does not match invoice {{3}} where it is stated as {{4}} units.`,
  invoice_deliverynote_totalamount_missmatch: `Invoice total amount "{{2}}" doesnt match with deliveryNote total amount {{3}}`,
  // PO & Invoice Discrepancy
  po_not_found: `Purchase Order #{{0}}, referenced in Invoice #{{1}}, is missing.`,
  po_not_found_in_any_items: `Purchase Order not found in any items referenced in Invoice #{{0}}`,
  po_invoice_item_count_miss_match: `The item count in Invoice #{{0}} does not match the item count in Purchase Order #{{1}}.`,
  po_not_attached: `Purchase order is not attached to Invoice #{{0}}.`,
  po_item_descriptionandcode_not_attached: `Item descriptions and codes are not attached to Invoice #{{0}} and Purchase Order #{{1}}.`,
  invoice_item_not_found_in_po: `The item with description "{{0}}" in Invoice #{{1}} is not found in Purchase Order #{{2}}`,
  po_item_quantity_is_zero: `The quantity of "{{0}}" in Purchase Order #{{1}} for Invoice #{{2}} is zero.`,
  invoice_item_quantity_greaterthan_po: `The quantity of item "{{0}}" on the Invoice #{{1}} is {{2}} units, which does not match Purchase Order #{{3}} where it is stated as {{4}} units.`,
  po_number_not_found_in_any_items: `Purchase order not found in any items in Invoice #{{0}}`,
  amount_not_match: `Invoice amount {{0}} does not match with simulated invoice amount {{1}}`,
  consumption_not_match: `Invoice consumption {{0}} does not match with simulated invoice consumption {{1}}`,
  po_invoice_amount_not_match: `The amount {{2}} mentioned in Purchase Order #{{1}}, does not match the amount {{3}} mentioned in Invoice #{{0}}`,
  discrepancy_po_empty: `PO number is empty in document # {{0}}`,
  discrepancy_invoice_po_not_found: `Purchase Order #{{1}} not found in Invoice #{{0}}`,
  discrepancy_invoice_po_amount_not_match: `Amount {{3}} in Purchase Order {{1}} doesn't match with Amount {{2}} in Invoice {{0}}`,
  vicio_discrepancy_po_amount_mismatch: `PO {{1}} has amount {{2}} which doesn't match with invoice's amount {{3}}`,
  vicio_discrepancy_po_notfound: `Purchase order not found`,
  // PO & Delivery Note Discrepancy
  invoice_po_ponumber_empty_invoice: "PO number is empty in Invoice # {{0}}",
  invoice_po_ponumber_not_found: `Purchase Order #{{1}}, associated with Invoice #{{0}}, is not found.`,
  delivery_note_quantity_greaterthan_po: `The quantity of item "{{0}}" on the Delivery Note #{{3}} is {{4}} units, which does not match Purchase Order #{{1}} where it is stated as {{2}} units`,
  delivery_note_quantity_lessthan_po: `The quantity of item "{{0}}" on the Delivery Note #{{3}} is {{4}} units, which does not match Purchase Order #{{1}} where it is stated as {{2}} units`,
  po_dn_matching_po_number_not_found_in_delivery_note: `Purchase Order not found in Delivery Note #{{0}}`,
  po_dn_matching_po_number_not_found_against_delivery_note_po_number: `Purchase Order #{{0}}, referenced in Delivery Note #{{1}}, is missing.`,
  po_dn_matching_po_and_delivery_note_items_count_not_matched: `The item count in Delivery Note #{{0}} does not match the item count in Purchase Order #{{1}}.`,
  po_dn_matching_item_description_and_refrence_code_not_found_in_delivery_note: `Item description and reference code of Purchase Order #{{0}} is not found in Delivery Note #{{1}}.`,
  po_dn_matching_po_item_not_found_in_deliverynote: `The item with description "{{0}}" in Purchase Order #{{2}} is not found in Delivery Note #{{1}}`,
  po_dn_matching_po_items_not_found: `Items not found in Purchase order #{{1}}`,
  po_dn_matching_delivery_note_item_quantity_is_zero: `The quantity of "{{0}}" in Delivery Note #{{3}} for Purchase Order #{{1}} is zero.`,
  discrepancy_dn_empty: `Delivery note is empty in document # {{0}}`,
  discrepancy_invoice_dn_not_found: `Delivery note #{{1}} not found in document #{{0}}`,
  discrepancy_invoice_dn_amount_not_match: `Amount {{3}} in Delivery note {{1}} doesn't match with Amount  {{2}} in Invoice {{0}}`,
  // Catalog Discrepancy
  item_price_didnot_match: `The unit price {{2}} of item with description "{{0}}" and reference code  {{1}} did not match with variation price {{3}} in catalog`,
  item_variation_not_found_in_catalog: `Item variation {{0}} with reference code {{1}} not found in catalog`,
  item_not_found_in_catalog: `Item {{0}} with reference code {{1}} not found in catalog`,
  item_code_empty: `Item {{0}} reference code {{1}} is empty`,
  PO_item_not_found: `No item found in Purchase Order #{{0}}`,
  // Workflow Invoice & Delivery Note Discrepancy
  workflow_discrepancy_InvoiceDNMatching_DeliveryNoteNotFound:
    "Delivery note not found(IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_ItemCountNotMatched:
    "Item count does not match(IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_DeliveryNoteItemsNotFound:
    "Delivery note items not found(IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_InvoiceItemDescriptionAndCodeNotAttached:
    "Invoice item description and code not attached(IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_InvoiceItemNotFoundInDN:
    "Invoice item not found in delivery note(IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_ItemQuantityGreaterThan:
    "Item quantity is greater than expected(IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_ItemQuantityLessThan:
    "Item quantity is less than expected(IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_PaymentTerms_DueDate:
    "Payment terms or due date mismatch(IN <> DN)",
  workflow_discrepancy_InvoicePOMatching_AmountNotMatch:
    "Amount does not match with the purchase order(IN <> PO)",
  workflow_discrepancy_PODNMatching_PONumberNotFound:
    "Purchase order number not found(PO <> DN)",
  workflow_discrepancy_PODNMatching_PONotFound:
    "Purchase order not found(PO <> DN)",
  workflow_discrepancy_PODNMatching_ItemCountNotMatched:
    "Item count does not match with purchase order(PO <> DN)",
  workflow_discrepancy_PODNMatching_POItemsNotFound:
    "Purchase order items not found(PO <> DN)",
  workflow_discrepancy_PODNMatching_DNItemDescriptionAndRefrenceCodeNotFound:
    "Delivery note item description and reference code not found(PO <> DN)",
  workflow_discrepancy_PODNMatching_DelveryNoteItemNotFoundInPO:
    "Delivery note item not found in purchase order(PO <> DN)",
  workflow_discrepancy_PODNMatching_DeliveryNoteItemQuantityZero:
    "Delivery note item quantity is zero(PO <> DN)",
  workflow_discrepancy_PODNMatching_DeliveryNoteItemQuantityGreaterthan:
    "Delivery note item quantity is greater than expected(PO <> DN)",
  workflow_discrepancy_PODNMatching_DeliveryNoteItemQuantityLessThan:
    "Delivery note item quantity is less than expected(PO <> DN)",
  workflow_discrepancy_InvoicePOMatching_PONumberNotFoundInInvoice: `Purchase order number not found in invoice.`,
  workflow_discrepancy_InvoicePOMatching_PONumberNotFound:
    "Purchase order number not found.",
  // Volotea Discrepancy
  volotea_discrepancy_item_matericaldesc_notfound: `material desc '{{3}}' not found for item #'{{0}}'`,
  volotea_discrepancy_item_po_notfound: `PO not found`,
  volotea_discrepancy_item_unitprice_mismatch: `Unit price '{{6}}' in PO '{{3}}-{{4}}' does not match with unit price '{{5}}'`,
  volotea_validation_item_sum_amount_totalamount_mismatch: `Sum of item's amount '{{0}}' doesn't match with total amount '{{1}}'`,
  volotea_validation_item_sum_amount_totalwithouttax_mismatch: `Sum of item's amount '{{0}}' doesn't match with total amount without tax '{{1}}'`,
  // Exotica Discrepancy
  exotica_discrepancy_item_bookrefr_empty: `Booking reference is missing in an item`,
  exotica_discrepancy_item_netsuiteitemid_empty: `Netsuite item isn't attached with an item`,
  exotica_discrepancy_item_po_notfound: `PO with booking reference '{{2}}' not found`,
  exotica_discrepancy_item_amount_mismatch: `Amount {{2}} in item doesn't match with amount {{3}} in PO with booking reference {{4}}`,
  exotica_discrepancy_totalamount_not_match: `Total amount '{{1}}' doesnt match with POs total amount '{{0}}'`,
  exotica_discrepancy_item_po_item_matching_notfound: `PO with booking reference '{{2}}' doenst have this NetsuiteItemId '{{3}}'`,
  exotica_discrepancy_item_po_not_exist: `PO with booking reference '{{2}}' doesnt exist`,
  // Account Class Segregation Discrepancy
  invoicednmatching_account_class_required: `Account class is required for matching invoice #{{0}} with delivery note `,
  invoicepomatching_account_class_required: `Account class is required for matching invoice #{{0}} with purchase order`,
  podnmatching_account_class_required: `Account class is required for matching purchase order #{{0}} with delivery note`,
  InvoiceDNMatching_ValidateAccountClass: `Validate account class`,
  InvoicePOMatching_ValidateAccountClass: `Validate account class`,
  PODNMatching_ValidateAccountClass: `Validate account class`,

  delete: `Delete `,
  document: `Document`,
  delete_document_approval: `Are you sure you want to delete this document?`,
  delete_documents_approval: `Are you sure you want to delete these documents?`,
  delete_all_documents: `Are you sure you want to delete all documents?`,
  delete_documents_warning: `Deleted document(s) cannot be recovered.`,
  deleted_document: `Document deleted Successfully`,
  cancel_button: `Cancel`,
  deleting: `Deleting`,
  actions: `Actions`,
  action: `Action`,
  view: `View`,
  my_account: `My account`,
  login_error: `Make sure all required fields are correctly filled in`,
  "Email is required": `Email is required`,
  "Password is required": `Password is required`,
  upload_documents: `Upload documents`,
  upload_by_email: `Upload by email`,
  upload_by_device: `Upload by device`,
  upload_from_device: `Upload from device`,
  upload_by_supplier: `Upload by supplier`,
  "Drop your files here or browse through your machine.": `Drop your files here or browse through your machine.`,
  a_maximum_of_10_files_are_allowed_in_a_single_upload: `Maximum 10 documents are allowed to upload at a single time`,
  only_one_document_is_allowed_to_upload: `Only one file is allowed to upload`,
  signIn_impersonate: `Signin Impersonate`,
  impersonate_error: `Please logout to impersonate an account`,
  token_not_found: `Token not found`,
  "All item's description is required.": `All item's description is required`,
  "All items(s) subtotalamount must be greater than zero.": `All items(s) subtotalamount must be greater than zero.`,
  "Document is reviewed": `Document is reviewed`,
  mapping: `Mapping`,
  mappings: `Mappings`,
  delete_mapping_approval: `Are you sure you want to delete this Mapping?`,
  delete_mappings_approval: `Are you sure you want to delete these Mappings?`,
  delete_all_mappings: `Are you sure you want to delete all Mappings?`,
  delete_mappings_warning: `Deleted Mapping(s) cannot be recovered.`,
  deleted_mapping: `Mapping deleted Successfully`,
  successfully_approved: `Successfully Approved`,
  successfully_rejected: `Successfully Rejected`,
  user_id: `User ID`,
  email_address: `Email Address`,
  pending_invitation: `Invitation Pending`,
  send_invite: `Send Invite`,
  password_changed_successfully: `Password changed successfully`,
  recovery_email_sent: `Recovery Email sent`,
  change_password: `Change password`,
  resend_invite: `Resend Invite`,
  selected: `Selected`,
  minutes: `minutes`,
  Default: `Default`,
  Custom: `Custom`,
  view_template: "View Header Template",
  document_type: `Document Type`,
  document_type_format: `Document Type Format`,
  field_selection: `Field Selection`,
  purchaseOrder: `Purchase Order`,
  deliveryNote: `Delivery Note`,
  receipt: `Receipt`,
  unknown: "Unknown",
  mandatory_fields: `Mandatory Fields`,
  optional_fields: `Optional Fields`,
  input_format: `Input Format`,
  date_culture: `Date Format`,
  language_input_format: `Language Input Format`,
  currency_format: `Currency Format`,
  currency_input_format: `Currency Input Format`,
  dot_is_thousand_comma_is_decimal: `"dot" is thousand separator and "comma" is decimal separator`,
  comma_is_thousand_dot_is_decimal: `"comma" is thousand separator and "dot" is decimal separator`,
  output_format: `Output Format`,
  date: `Date`,
  save: `Save`,
  successfully_saved: `Successfully Saved`,
  something_went_wrong: `Something Went Wrong`,
  similar_fields_not_allowed: `Similar fields not allowed`,
  general_configuration: `General Configuration`,
  input_CSV_mapping: `Input CSV Mapping`,
  output_CSV_mapping: `Output CSV Mapping`,

  select_format: `Select a Format`,
  add_custom_field: `Add Custom Field`,
  vendor_company_ID: `Vendor Company ID`,
  vendor_wise_config: `Vendor wise Configuration`,
  vendorId_already_exists: `Vendor ID already exists`,
  customer_company_ID: `Customer Company ID`,
  customer_wise_config: `Customer wise Configuration`,
  customerId_already_exists: `Customer ID already exists`,
  upload: `Upload`,
  file_uploaded_successfully: `File Uploaded Successfully`,
  upload_CSV_file: ` Upload your CSV file for mapping`,
  uploaded_file: `Uploaded File`,
  download_CSV: `Download CSV`,
  file_deleted_successfully: `File Deleted Successfully`,
  sample: `Sample CSV`,
  uploaded: `Uploaded CSV`,
  delete_file: `Delete CSV`,
  dialog_title: `Delete CSV`,
  dialog_text: `Are you sure you want to delete the CSV file?`,
  cancel: `Cancel`,
  yes: `Yes`,
  no: `No`,
  upload_CSV_file_tooltip: `Mapping file let you add fields and values in JSON which are not part of document but requires in your ERP.`,
  deactivate: `Deactivate`,
  activate: `Activate`,
  time_interval: `Time Interval`,
  daily: `Daily`,
  weekly: `Weekly`,
  monthly: `Monthly`,
  activated: `Activated`,
  deactivated: `Deactivated`,
  samplecsv1: `If VendorCompanyID equals to A87803355 then InternalCompanyCode would be 3359 and ProviderCode would be 7766`,
  samplecsv2: `If VendorCompanyID equals to A87803356 then InternalCompanyCode would be 3358`,
  samplecsv3: `If VendorCompanyID equals to A87803355 and InternalCompanyCode equals 3359 and ProviderCode like 'Calle sesea' then ProjectCode would be ALUC`,
  sample_eq: "eq = Equal",
  sample_lk: "lk = Like or Contain",
  csv_format_required: `CSV Format is required`,
  email_invalid: `Email is invalid`,
  email_required: `Email is required`,
  time_interval_required: `Time Interval is required`,
  template_format: `Template format`,
  view_template_format: `View template format`,
  webhook: `Webhook`,
  destination_URL: `Webhook URL`,
  Enter_Webhook_URL: `Enter the URL where your Webhook should send data`,
  configure_webhook: `Configure Webhook`,
  webhook_key: `Webhook Key`,
  select_events: `Events to Send`,
  select_webhook_events: `Select the events that should trigger your webhook`,
  destination_url_required: `Destination URL is required`,
  events_required: `Provide at least one event`,
  configured_webhook: `Configured Webhook Successfully`,
  Activate: `Activate`,
  url_validation: `Invalid URL`,
  "Ignored and Mandatory fields must be unique.": `Ignored and Mandatory fields must be unique.`,
  webhook_key_not_configured: `Not Configured`,
  date_format_error: `Date Format is empty`,
  documentV2: `Document Configuration`,
  Invoice: `Invoice`,
  DeliveryNote: `Delivery Note`,
  PurchaseOrder: `Purchase Order`,
  custom: `Custom`,
  SimulatedInvoice: `Simulated Invoice`,
  timeInterval: `Time interval in minutes`,
  new_mapping: `New mapping configuration`,
  bulk_configuration: `Bulk configuration`,
  individual_configuration: `Individual configuration`,
  bulk_csv_upload: `Upload CSV for bulk mapping `,
  download: `Download`,
  set_mapping_condition: `Set mapping condition`,
  if_variable: `If the variable`,
  select_variable: `Select a variable`,
  is_or_contain: `is or contains`,
  select_condition: `Select a condition`,
  to_value: `to the value`,
  enter_value: `Enter a value`,
  add_condition: `Add condition`,
  the_variable: `The variable`,
  would_be: `Would be`,
  then: `Then`,
  the_field: `The field`,
  mapping_configured: `Mapping configured`,
  bulk_mapping_first_text: `Download our sample CSV template for the configuration.`,
  sample_csv_template: `Sample CSV`,
  bulk_mapping_second_text: `Drop the CSV here or click browse through your machine.`,
  bulk_mapping_third_text: `Keep previous data`,
  confirm_upload_text: `Are you sure you want to upload this CSV? Once CSV is uploaded previous data will be lost.`,
  confirm_upload: `Upload CSV`,
  download_mapping: `Download mapping`,
  sample_csv_info: `Following operators can be used to define mapping conditions`,
  eq_equal: `eq = Equal to`,
  lk_like: `lk = Like`,
  sw_startsWith: "sw = Starts With",
  nsw_notStartsWith: "nsw = Not starts with",
  add_export_mapping: `Add export mapping`,
  mapping_delete: `Delete mapping`,
  delete_mapping_warning: `Deleted mapping(s) cannot be recovered`,
  id: `ID`,
  receiveType: `Recieve Type`,
  template: `Template`,
  deActive: `Deactive`,
  intervalType: `Interval Type`,
  customIntervalInMinutes: `Custom Interval In Minutes`,
  connector: `Connector`,
  template_required: `Template is required`,
  connector_required: `Storage Connector is required`,
  add_export_configuration: `Add Export Configuration`,
  integration_not_connected: `No integration is connected, kindly connect and retry`,
  "Output csv configured with these configurations.": `Output csv configured with these configurations.`,
  connector_differenttype_notallowed: `Connector of different type not allowed`,
  "You cannot connect more than one output connector.":
    "You cannot connect more than one output connector.",
  output_mapping_configured: `Output Mapping Configured`,
  GreaterThanOrEqual: `Greater Than or Equal To`,
  LessThan: "Less Than",
  LessThanOrEqual: "Less Than or Equal",
  Equal: `Equal`,
  TotalAmount: `Total Amount`,
  DocumentStatus: `Document Status`,
  Airthmatic: `Airthmatic`,
  NeedReview: `Need Review`,
  WorkflowType: `Workflow Type`,
  Parameter: `Parameter`,
  Operation: `Operation`,
  InputValue: `Input Value`,
  Approver: `Approver`,
  Action: `Action`,
  "Add Workflow": "Add Workflow",
  name: "Name",
  "Add Condition": "Add Condition",
  Document: "Document",
  Email: "Email",
  "workflows/configuration": "Review document",
  Submit: "Submit",
  Extracted: "Extracted",
  "Document Status": "Document Status",
  "Total Amount": "Total Amount",
  NotEqual: "Not Equal",
  GreaterThan: "Greater Than",
  "Customer Company": "Customer Company",
  "Invoice Id": "Invoice Id",
  "Invoice Date": "Invoice Date",
  "Customer Company Identification": "Customer Company Identification",
  "Total With Out Tax": "Total With Out Tax",
  "Total Tax": "Total Tax",
  "PO Number": "PO Number",
  "Customer Company Address": "Customer Company Address",
  "Vendor Company Name": "Vendor Company Name",
  "Vendor Company Id": "Vendor Company Id",
  "Vendor Company Address": "Vendor Company Address",
  "Name is required": "Name is required",
  "Parameter is required": "Parameter is required",
  "Operation is required": "Operation is required",
  "Input Value is required": "Input Value is required",
  Delete: "Delete",
  "Created On": "Created On",
  "Workflow Name": "Workflow name",
  disable_workflow_approval: `Disable workflow approval`,
  workflow_info_0: `Disable  Approval`,
  workflow_info_1: `This will always keep document attached with  this workflow.`,
  workflow_info_2: `Useful when someone want to distribute documents from main account to sub-account`,
  disable_approval: `Disable Approval`,
  approval_not_required: `Approval not required`,
  workflow_created_successfully: `Workflow created successfully`,
  "Document workflows": "Document workflows",
  review_allowed: `Review Allowed`,
  "Allow Review Document": "Allow Review Document",
  Yes: "Yes",
  No: "No",
  "Atleast 1 approver is required": "Atleast 1 approver is required",
  "New Workflow": "New Workflow",
  "Approval Required": "Approval Required",
  export_csv: `Export CSV`,
  "Export csv(s)": `Export csv(s)`,
  review_: "Review",
  AND: "AND",
  duplicateFieldErrorMessage: "document {{documentId}} has duplicate field",
  accountClassErrorMessage: `Account class is required for documentId {{documentId}}`,
  "Are you sure you want to delete this row?":
    "Are you sure you want to delete this row?",
  "Delete this row?": "Delete this row?",
  "Are you sure you want to delete this field?":
    "Are you sure you want to delete this field?",
  "Delete this field?": "Delete this field?",
  "Mapping is not valid": "Mapping is not valid",
  docs_per_month: " {{count}} docs/month",
  "50 docs/month": "50 docs/month",
  "200 docs/month": "200 docs/month",
  "Standard accuracy": "Standard accuracy",
  "Basic support": "Basic support",
  "Unlimited users": "Unlimited users",
  "600 docs/month": "600 docs/month",
  "Priority support": "Priority support",
  "Custom plan": "Custom plan",
  "High accuracy": "High accuracy",
  "Premium support": "Premium support",
  "Ad-hoc": "Ad-hoc",
  Free: "Free",
  Documents: "Documents",
  "Click connect to login your Quickbooks account and select company which you want to integrate.\nVendors & Items name should be same for smooth integration.":
    "Click connect to login your Quickbooks account and select company which you want to integrate.\nVendors & Items name should be same for smooth integration.",
  "Select your OneDrive folder": "Select your OneDrive folder",
  "successfully configured": "successfully configured",
  "failed to configure": "failed to configure",
  Configure: "Configure",
  Disconnect: "Disconnect",
  Connect: "Connect",
  "Connect Dost with your ": "Connect Dost with your ",
  " account": " account",
  "Email send successfully": `Email send successfully`,
  "Request Partner Assistance to Connect SAPB1":
    "Request Partner Assistance to Connect SAPB1",
  "Dost partners with Artero Consultores (SAP Gold Partner) to ease integration with SAP B1. Specify your version and potential patches (Help > About SAP B1) and request assistance for your integration.":
    "Dost partners with Artero Consultores (SAP Gold Partner) to ease integration with SAP B1. Specify your version and potential patches (Help > About SAP B1) and request assistance for your integration.",
  "Alternatively, you can find the": "Alternatively, you can find the",
  "technical documentation": "technical documentation",
  "here to handle the integration yourself.":
    "here to handle the integration yourself.",
  "SAP Business 1 version and patches": "SAP Business 1 version and patches",
  "Request Partner Assistance": "Request Partner Assistance",
  "desc-businesscentral_output_connector":
    "Connect your Business Central with custom api as mentioned here: <link1></link1>",
  "desc-ftp_upload_output_connector": "Connect Dost with your FTP server.",
  "desc-holded_output_connector":
    "Please go to the link: <link1></link1> and copy/paste your Holded key below:",
  "desc-ftp_download_input_connector": "Connect Dost with your FTP server.",
  "desc-quickbook_output_connector":
    "Click connect to login your Quickbooks account and select company which you want to integrate.\nVendors & Items name should be same for smooth integration.",
  "desc-delsol_output_connector": `Please provide the details below to setup your delsol integration`,
  "desc-onedrive_output_connector":
    "Click Connect to login into your account and select folder by clicking configure option",
  "desc-ftp_input_connector": "Connect Dost with your FTP server.",
  "desc-businesscentral_v2_output_connector":
    "Click Connect to login into your Business Central account",
  "Incorrect password.": `Incorrect password`,
  deliverynotenumber: `Delivery Note`,
  invoicenumber: `Invoice`,
  itemdescription: `Invoice item`,
  deliverynoteitemquantity: `Delivery note item quantity`,
  invoiceitemquantity: `Invoice item quantity`,
  ponumber: `PO number`,
  poitemquantity: `PO item quantity`,
  see_related_docs: `See related documents`,
  replace_duplicate: `Replace duplicate`,
  replace_duplicate_explain: `Whenever a new invoice is detected as a duplicate in Dost, the system will replace the old invoice with the new one.`,
  erp_sync_configuration: `ERP Sync Configuration`,
  no_erp_connected: `You don't have any ERP connected yet`,
  add_an_erp: `Add an ERP`,
  created_successfully: `Created successfully`,
  updated_successfully: `Updated Successfully`,
  all: `All`,
  connected_erp_name: `Conected ERP`,
  erp_vendors: `ERP Vendors`,
  internalId: `Internal ID`,
  extractedId: `Extracted ID`,
  update_erp_vendors: `Update ERP Vendors`,
  update: `Update `,
  erp_vendor: `ERP Vendor`,
  po_status_mapping: `PO Status Mapping`,
  Open: `Open`,
  Closed: `Closed`,
  empty_fields_not_allowed: `Status Field is required`,
  when_documentType_is_received: `PO status when {{documentType}} is received`,
  po_status: `PO status`,
  disconnected_successfully: `{{erpName}} disconnected successfully`,
  connected_successfully: `{{erpName}} connection request sent`,
  open_folder: `Open Folder`,
  generate_auth_key: `Generate Auth Key`,
  regenerate: `Regenerate`,
  field_is_required: `Field is required`,
  custom_field: `Custom Field`,
  custom_field_validation: `Field cannot contain white space, special characters and numbers`,
  select_atleast_one_folder: `Folder selection is required`,
  input_mapping_info_0: `Here you can add`,
  input_mapping_info_1: `Static: A static number or text. Example: 12`,
  input_mapping_info_2: `Dynamic: An already existing variable between "{ }". Example: {vendorCompanyID}`,
  input_mapping_info_3: `Formula: You can also create simple formulas with the existing variables. Use variables between "{ }". Example: {totalTax} + 2 x {totalAmount}`,
  input_mapping_info: `
  Here you can add\\n1. An already existing variable between "{ }". Example: {vendorCompanyID}\\n2. A static number or text. Example: 12.\\n3. You can also create simple formulas with the existing variables. Use variables between "{ }". Example: {totalTax} + 2 x {totalAmount}`,
  edit_input_mapping: `Edit Mapping`,
  duplicate_input_mapping: `Duplicate Mapping`,
  delete_input_mapping: "Delete Mapping",
  original_document: `Original Document`,
  search_field: `Search Field`,
  enter_ruleValue: `Enter Rule Value`,
  enter_resultValue: `Enter Result Value`,
  ruleValue_or_resultValue_required: `Either Rule Value or Result Value is required`,
  searchField_required: `Search Field required`,
  select_field: `Select Field`,
  select_your_connector_folder: `Select your {{connectorName}} folder`,
  GoogleDrive: `Google Drive`,
  OneDrive: `One Drive`,
  connect_dost_with_your_connector_account: `Connect Dost with your {{connectorName}} account`,
  no_configuration: `You don't have any configuration yet`,
  delete_workflow: `Delete Workflow`,
  delete_workflow_approval: `Are you sure you want to delete this workflow?`,
  delete_workflow_warning: `Deleted Workflow cannot be recovered`,
  // Document Validation Messages
  document_type_unknown: `Document type is unknown`,
  mandatory_fields_missing: `Mandatory field(s) are missing`,
  mandatory_fields_confidence_low: `Mandatory field(s) confidence is low`,
  invoicedate_is_missing: `Invoice Date is missing`,
  duedate_is_missing: `Due Date is missing`,
  item_description_missing: `Item description is missing`,
  item_amountsum_notequal_totalwithouttax: `Item amount sum is not equal to Total without Tax`,
  item_taxsum_notequal_totalttax: `Item tax sum is not equal to Total Tax`,
  vendoraddress_length_greater_130: `Vendor address length is greater than 130 characters`,
  customeraddress_length_greater_130: `Customer address length is greater than 130 characters`,
  document_being_processed: `Document is being processed`,
  document_is_processing: `Document is being processed`,
  document_has_processed: `Document has been processed`,
  document_is_duplicate: `Document is duplicate`,
  doc_integrated_success: `Document has been integrated`,
  document_has_failed: `Document has been failed`,
  document_need_review: `Document needs to be reviewed`,
  document_has_reviewed: `Document has been reviewed`,
  "Document has been integrated": `Document has been integrated`,
  "document is not valid. PDF header signature not found.": `Document is not valid. PDF header signature not found.`,
  "document is not valid. Unknown encryption type R = 5": `Document is not valid. Unknown encryption type R = 5`,
  "document is not valid. This invalid PDF file doesn't have a catalog.": `Document is not valid. This invalid PDF file doesn't have a catalog.`,
  "Review Document": `Review Document`,
  duedate_missing: `Due date is missing`,
  "Unexpected response from Holded server. Documents limit possibly has been reached.": `Unexpected response from Holded server. Documents limit possibly has been reached.`,
  "Folder is not selected. Please select folder first to process document.":
    "Folder is not selected. Please select folder first to process document.",
  "Limit exceeded": `Limit exceeded`,
  "Document has been uploaded": `Document has been uploaded`,
  ftp_intg_doc_json_disable: "Document integration is disabled",
  // Document Validation Messages with Values
  item_unitprice_missing: `Item '{{0}}' Unit Price is missing`,
  item_quantity_missing: `Item '{{0}}' Quantity is missing`,
  item_amount_missing: `Item '{{0}}' Amount is missing`,
  totalamount_should_be: `Total Amount amount should be {{0}}`,
  doc_validation_totalwithouttax_should_be: `Total without tax should be {{0}}`,
  tax_baseamount_sum_notequal_totalwithoutTax: `Sum of tax's 'Base-Amount' isn't equal to 'Total-Without-Tax`,
  tax_baseamount_sum_notequal_totalwithoutTaxExclDiscount: `Sum of tax's base amount isn't equal to 'total without tax excluding discount`,
  processed_mandatory_fields_invalid: `{{0}} has invalid value`,
  doc_table_is_missing: `{{0}} is missing`,
  doc_table_is_empty: `{{0}} is empty`,
  doc_table_field_is_missing: `{{0}} table {{2}} field is missing in row# {{1}}`,
  doc_table_processed_mandatory_fields_invalid: `Invalid data in {{0}} table {{2}} field in row# {{1}}`,
  doc_field_validation_formula_incorrect: `Values does'nt match in formula {{0}}`,
  doc_validation_item_amount_unitprice_mismatch: `Item #{{0}} amount doesnt match with (qty*unitprice)`,
  doc_validation_item_sum_amount_totalamount_mismatch: `Sum of item's amount doesn't match with total amount
`,
  // Workflow
  "Workflow name must be unique": "Workflow name must be unique",
  workflow_exists: `Workflow already exists`,
  permission_not_granted: `Permission not granted`,
  NeedApproval: `Approval required`,
  Approved: `Approved`,
  Rejected: `Rejected`,
  // Payments
  payments: `Payments`,
  payment: `Payment`,
  amount: `Amount`,
  createdOn: `Created on`,
  paymentSource: `Payment Source`,
  related_documents: `Related Documents`,
  emailAccount: `Email Account`,
  emailDate: `Email Date`,
  emailPayment: `Email Payment`,
  emailAmount: `Email Amount`,
  emailProducedBy: `Email Produced By`,
  payment_details: `Payment Details`,
  no_payments_found: `No Payments Found`,
  discrepancy_detected: `Discrepancy Detected`,
  extracting_documents: `Extracting Documents`,
  no_related_documents_found: `No related documents found`,
  subject: `Subject`,
  subject_required: `Subject is required`,
  subject_default_text: `I am interested in the Enterprise Offering`,
  message: `Type Message`,
  message_required: `Message is required`,
  email_sent: `Email is sent`,
  workflow_name: `Workflow Name`,
  approvedOn: `Approved On`,
  approver: `Approved by`,
  rejectedOn: `Rejected on`,
  rejectedBy: `Rejected by`,
  message_default_text: `Please add any further details below this line and we will get back to you in few hours
  <br /> 
 <h2> --------------------------------------------------------------------------------- </h2>
 <hr></hr>`,
  payment_gateway_connected: `Payment gateway connected`,
  payment_gateway_connected_successfully: `{{paymentGateway}} connected successfully`,
  payment_gateway_connection_request_sent: `{{paymentGateway}}: Connection request sent`,

  create_payment: `Create payment`,
  payee_details: `Benificiary details`,
  payee_id: `Benificiary ID`,
  payee_name: `Benificiary name`,
  total_documents_amount: `Total documents amount`,
  document_details: `Document details`,
  account_details: `Account details`,
  account_number: `Account number`,
  account_id: `Account ID`,
  pay_now: `Pay now`,
  pay: `Pay`,
  accountNumber: `Account number`,
  accountNumber_required: `Account number is required`,
  confirm_payment: `Payment Confirmation`,
  confirm_payment_text: `Are you sure you want to pay {{payeeName}} a total amount of {{totalAmount}}`,
  vendorCompanyId: `Vendor company ID`,
  totalInvoicesAmount: `Total invoices amount`,
  paidAmount: `Paid amount`,
  invalid_account_number: `Invalid account number`,
  payment_made_successfully: `Payment successful`,
  make_payment: `Make payment`,
  payment_status: `Payment status`,
  payment_is_being_processed: `Payment is being processed`,
  payment_status_with_value: `Payment {{paymentStatus}}`,
  UnPaid: `Unpaid`,
  Paid: `Paid`,
  Due: `Due`,
  Process: `Processing`,
  Processing: `Processing`,
  UnKnown: `Unknown`,
  accountclass_required: `Account class required`,
  "#": `#`,
  select_payment_method_text: `How do you wish to proceed with payment to {{payeeName}}?`,
  free: `Free`,
  no_payment_gateway_connected: `You don't have any payment gateway connected`,
  add_a_payment_gateway: `Add payment gateway`,
  CardNumber_is_required: `Card number is required`,
  Amount_is_required: `Amount is required`,
  CVC_is_required: `CVC is required`,
  CardNumber: `Card number`,
  Amount: `Amount`,
  vendor_details: `Vendor details`,
  vendor_company_name: `Vendor company name`,
  vendor_id: `Vendor ID`,
  customer_details: `Customer details`,
  customer_company_name: `Customer company name`,
  customer_id: `Customer ID`,
  payment_method_selection: `Select Payment Method`,
  please_select_a_payment_method: `Please select a payment method`,
  payment_method_details: `Pay amount`,
  payment_summary: `Payment summary`,
  balance: `Available balance`,
  account_title: `Account title`,
  bank: `Bank`,
  payment_supplier_not_created: `Supplier not created`,
  payment_supplier_account_info_not_found: `Supplier account information not found`,
  payment_invoice_already_paid: `Invoice already paid`,
  total_amount: "Total: € {{totalDocumentsAmount}}",
  failed_amount: `Failed: € {{failedDocumentsAmount}}`,
  transaction_amount: `Success: € {{transactionAmount}}`,
  payment_gateway_not_connected: `Payment Gateway not connected`,
  "payment_all_selected_document(s)_shouldbe_processed_or_reviewed":
    "Selected documents(s) should be processed or reviewed",
  payment_account_class_should_be_accountpayable: `Account class should be account payable`,
  "payment_all_selected_document(s)_type_shouldbe_invoice":
    "Selected document(s) type should be invoice",
  payment_paid_amunt_equal_selected_document_amount:
    "Paid amount should be equal to selected document amount",
  "payment_all_selected_document(s)_type_invoice":
    "Selected document types should be invoice",
  payment_account_class_shouldbe_account_payable:
    "Account class should be account payable",
  payment_method_is_required: `Payment method is required`,
  payment_invoice_not_found: "Invoice not found",
  payment_select_not_deleted_document: `Delected document(s) cannot be selected`,
  process_payment: "Process payment",
  payment_successful: `Payment successful`,
  your_payment_successfully_processed: `Your payment has been successfully processed`,
  total_payment: `Total payment`,
  payment_method: `Payment method`,
  payment_process_time: `Payment process time`,
  sender_name: `Sender name`,
  transaction_reference_id: `Transaction refernce ID`,
  download_receipt: `Download Receipt`,
  payable_amount: `Payable amount`,
  vendor_status: `Vendor status`,
  documents_attached: `{{totalDocuments}} document(s)`,
  vendor_detail: `Vendor detail`,
  supplier_details: `Supplier details`,
  create_supplier: `Create supplier`,
  payment_has_been_processed: ` is processed successfully`,
  payment_you_have_insufficient_balance_to_complete_transaction: `You have insufficient balance to process transaction`,
  created_by: `Created by`,
  created_on: `Created on`,
  "payment_all_selected_document(s)_workflow_shouldbe_approved": `Selected document(s) workflow must be approved`,
  payment_method_not_found: `Payment method not found`,
  payment_process_successful: `Payment process successful`,
  review_document_payment_has_been_initiated_you_are_not_able_to_review_document: `Payment has been initiated you are not allowed to review document`,
  review_document_document_has_been_paid_you_are_not_able_to_review: `Payment has been paid you are not allowed to review document`,
  transaction_id: `Transaction ID`,
  transaction_details: `Transaction Details`,
  processed_transactions: `Processed transactions`,
  failed_transactions: `Failed transactions`,
  successed_transactions: `Successed transactions`,
  transactions: `Transactions`,
  count: `Count`,
  Success: `Success`,
  Fail: `Failed`,
  Failed: `Failed`,
  total_processed: `In process`,
  total_failed: `Fail`,
  total_successed: `Success`,
  total: `Total`,
  reason: `Reason`,
  denied_payment: `Payment Denied`,
  blocked_account: `Blocked Account`,
  closed_account: `Closed Account`,
  integration_error: `Integration Error`,
  invalid_bank: `Invalid Bank`,
  suspended_bank: `Bank Suspended`,
  wrong_account_number: ` Wrong Account Number`,
  wrong_amount: `Wrong Amount`,
  wrong_operation_code: `Wrong Operation Code`,
  processor_failure: `Processor Failure`,
  payment_supplier_onboarding_incomplete: `Supplier onboarding incomplete`,
  payment_gateway_account_not_activated: `Account not activated`,
  paymentgateway_account_creation_request_pending: `Account request pending`,
  paymentgateway_notallowed_toconnect_multiplegateways: `Not allowed to connect multiple gateway`,
  paymentgateway_not_found: ` Payment gateway not found`,
  paymentgateway_account_creation_request_inproces: `Account creation in process`,
  paymentgateway_account_creation_request_inprocess: `Account creation request in process`,
  sub_total: `Sub total`,
  "fee_0%": "Fee 0%",
  "Bnak To Bank": `Bank to Bank`,
  invoice_payments: `Invoice payments`,
  bank_transactions: `Bank transactions`,
  //  Suppliers
  registration_number: `Registration number`,
  registration_number_required: `Registration number is required`,
  registration_number_validation1: `Registration number cannot be greater than 20 characters`,
  registration_number_validation2: `A valid registration number is required`,
  account_holder_name: `Account holder name`,
  iban: `IBAN`,
  iban_validation_1: `IBAN must consist of 24 charachters`,
  iban_validation_2: `Only numbers and alphabets are allowed`,
  account_creation_in_process: `Account creation in process`,
  in_process: `In process`,
  //  Catalog
  catalog: `Catalog`,
  items: `Items`,
  item: `Item`,
  add_catalog_items: `Add Items`,
  bulk_catalog_items: `Upload Bulk Items`,
  set_individual_catalog_items: `Add Individual Item`,
  catalog_items: `Items`,
  no_catalog_items: `You have no items yet`,
  code: `Code`,
  price: `Price`,
  item_variations: `Item Variations`,
  item_name: `Item Name`,
  item_code: `Item Code`,
  item_price: `Item Price`,
  catalog_item_name_required: `Item Name required`,
  catalog_item_code_required: `Item Code required`,
  catalog_item_price_required: `Item Price required`,
  catalog_item_price_validation: `Item Price must be a number`,
  catalog_item_edit: `Edit item`,
  catalog_item_delete: `Delete item`,
  catalog_item_deleted_successfully: `Item deleted successfully`,
  catalog_item_update_successfully: `Item updated successfully`,
  catalog_item_added_successfully: `Item added successfully`,
  add_item_variation: `Add item variation`,
  no_item_variation: `No item variation found`,
  unit_price: `Unit price`,
  customerId: `Customer ID`,
  customerId_required: `Customer ID is required`,
  add_item: `Add item`,
  variations: `Variations`,
  add_variation: `Add variation`,
  item_vendorId_required: `Vendor ID is required`,
  item_price_validation: `Item price is required`,
  item_variation_edit: `Edit variation`,
  item_variation_delete: `Delete variation`,
  item_variation_created: `Item variation added successfully`,
  item_variation_deleted: `Item variation deleted successfully`,
  edit_item_variations: `Edit item variation`,
  item_variation_edited: `Item variation updated successfully`,
  delete_approval: `Are you sure you want to delete this`,
  delete_approvals: `Are you sure you want to delete these {{deleteEntity}}s?`,
  delete_item_warning: `If item is deleted then it's related variation will also be deleted`,
  delete_variation_warning: `Deleted variation cannot be recovered`,
  bulk_item_first_text: `1. Download our CVS template for bulk items`,
  "Either Vendor or Customer ID is required": `Either Vendor or Customer ID is required`,
  "Vendor ID should be empty when Customer ID is filled": `Vendor ID should be empty when Customer ID is filled`,
  "Customer ID should be empty when Vendor ID is filled": `Customer ID should be empty when Vendor ID is filled`,
  "Invalid unit price": `Invalid unit price`,
  "The amount should be a maximum of four digits after decimal": `The amount should be a maximum of four digits after decimal`,
  unitPrice_required: `Unit price is required`,
  price_must_be_greater_than_zero: `Price must be greater than 0`,
  unitPrice_must_be_greater_than_zero: `Unit price must be greater than 0`,
  // Export Configuration (Custom Template)
  new_custom_template: `New Custom Template`,
  templateType: `Template Type`,
  editTemplate: `Edit Template`,
  customTemplate: `Custom Template`,
  templateNameRequired: `template name is required`,
  fieldNameRequired: `field name is required`,
  fieldDescriptionRequired: `field description is required`,
  SystemDefined: `System defined`,
  templateName: `Template Name`,
  isDefault: `Is Default`,
  fieldName: `Field Name`,
  fieldDescription: `Field Description`,
  add_field: `Add Field`,
  template_cannot_have_duplicate_fields: `Template cannot have duplicate fields`,
  templateCreatedSuccessfully: `Template created successfully`,
  templateUpdatedSuccessfully: `Template updated successfully`,
  duplicateFieldFound: `Duplicate fields found.`,
  headerFieldFound: `Position the table fields at the end of the template`,
  custom_template_info_0: `Here you can create  Custom template`,
  custom_template_info_1: `Multiple templates can be created`,
  custom_template_info_2: `Only one can be set as default`,
  custom_template_info_3: `Default template is used when document's CSV is downloaded on document searching page`,
  deleteTemplate: `Delete Template`,
  custom_template_delete: `Delete {{templateName}} Template`,
  custom_template_deleted: `Custom template deleted successfully`,
  delete_template_warning: `Deleted template cannot be recovered`,
  template_deleted_successfully: `Template deleted successfully`,
  default: `Default`,
  templates: `Templates`,
  // Email Inbox
  inbox_is_empty: `Inbox is empty`,
  mail_inbox: `Mail inbox`,
  attachments: `Attachments`,
  to: `to`,
  me: `me`,
  clear_all: `Clear all`,
  no_attachments: `No Attachments`,
  no_mail: `No mail`,
  inbox: `Inbox`,
  no_subject: `No subject`,
  no_mail_selected: `No mail selected`,
  attachment_name: `Attachment Name`,
  record: `Record`,
  startDate: `Start Date`,
  endDate: `End Date`,
  search: `Search`,
  export_document: `Export document(s)`,
  template_is_required: `Please select a template`,
  download_via_template: `Download via template`,
  // Create Document
  create_document: `Create document`,
  account_information: `Account information`,
  document_information: `Document information`,
  customer_information: `Customer information`,
  vendor_information: `Vendor information`,
  items_information: `Items information`,
  taxes_information: `Taxes information`,
  account_class: `Account class`,
  document_class: `Document class`,
  optional: `Optional`,
  account_receivable: `Account receivable`,
  account_payable: `Account payable`,
  accountClass_is_required: `Account class is required`,
  documentClass_is_required: `Document class is required`,
  back: `Back`,
  next: `Next`,
  invoiceID: `Invoice ID`,
  documentNumber: `Document number`,
  poNumber: `PO number`,
  "total(WithoutTax)": `Total (without tax)`,
  "tax_amount_not_%": "Tax (amount)",
  totalTax: `Total tax`,
  taxPercentage: `Tax percentage`,
  documentDate: `Document date`,
  dueDate: `Due date`,
  invoiceId_is_required: `Invoice Id is required`,
  documentNumber_is_required: `Document number is required`,
  poNumber_is_required: `PO number is required`,
  totalWithoutTax_is_required: `Total without tax is required`,
  totalTax_is_required: `Total tax is required`,
  totalAmount_is_required: `Total amount is required`,
  taxPercentage_is_required: `Tax percentage is required`,
  deliveryNote_is_required: `Delivery note is required`,
  documentDate_is_required: `Document date is required`,
  dueDate_is_required: `Due date is required`,
  customerCompanyName: `Customer company name`,
  customerCompanyID: `Customer company ID`,
  customerCompanyAddress: `Customer company address`,
  customerEmail: `Customer email`,
  DocumentCategory: "Document Category",
  customerCompanyName_is_required: `Customer company name is required`,
  customerCompanyID_is_required: `Customer company ID is required`,
  customerEmail_is_required: `Customer email is required`,
  customerCompanyAddress_is_required: `Customer company address is required`,
  vendorCompanyName: `Vendor company name`,
  vendorCompanyID: `Vendor company ID`,
  vendorCompanyAddress: `Vendor company address`,
  vendorCompanyName_is_required: `Vendor company name is required`,
  vendorCompanyID_is_required: `Vendor compandy ID is required`,
  vendorCompanyAddress_is_required: `Vendor company address is required`,
  referenceCode: `Reference code`,
  unit: `Unit`,
  unitPrice: `Unit price`,
  vatPercentage: `VAT %`,
  vat: `VAT`,
  dtoPercentage: `DTO %`,
  dto: `DTO`,
  discountPercentage: `Discount %`,
  discount: `Discount`,
  subTotalAmount: `Subtotal amount`,
  referenceCode_is_required: `Reference code is required`,
  description_is_required: `Description is required`,
  unit_is_required: `Unit is required`,
  unitPrice_is_required: `Unit price is required`,
  quantity_is_required: `Quantity is required`,
  vatPercentage_is_required: `VAT percentage is required`,
  vat_is_required: ` VAT is required`,
  dtoPercentage_is_required: `DTO percentage is required`,
  dto_is_required: `DTO is required`,
  discountPercentage_is_required: ` Discount percentage is required`,
  discount_is_required: `Discount is required`,
  subTotalAmount_is_required: `Sub total amount is requied`,
  amount_is_required: `Amount is required`,
  delete_item: `Delete item`,
  update_item: `Update item`,
  delete_items: `Delete items`,
  taxes: `Taxes`,
  baseAmount: `Base amount`,
  add_tax: `Add tax`,
  update_tax: `Update tax`,
  submit: `Submit`,
  reset: `Reset`,
  atleast_one_item_is_required: `Atleast one item is required`,
  atleast_one_tax_is_required: `Atleast 1 tax information is required`,
  submit_and_create_new_document: `Submit and create new document`,
  baseAmount_is_required: `Base amount is required`,
  add_valid_date: `Add valid date`,
  vendorEmailAddress: `Vendor email address`,
  vendorEmail_is_required: `Vendor email address is required`,
  dueDate_should_be_greater_than_documentDate: `Due date should be greater than document date`,
  documentDate_should_be_less_than_dueDate: `Document date should be less than due date`,
  companyName: `Company Name`,
  companyID: `Company ID`,
  companyAddress: `Company Address`,
  companyEmail: `Company Email`,
  logo_url: `Logo URL`,
  tax: `Tax`,
  document_is_required: `Document is required`,
  upload_document: `Upload document`,
  tax_is_required: `Tax is required`,
  document_created_successfully: `Document created successfully`,
  // Analytics
  serial_number: `S.No`,
  documentCount: `Document Count`,
  top_vendors: `Top vendors`,
  vendor_dependency: `Vendor dependency`,
  uploaded_documents: "Uploaded documents",
  doc_class_distribution: `Document class distribution`,
  "Total amount": `Total amount`,
  averageDocumentAmountPerMonth: `Average document amount`,
  totalAmountPerDocumentClass: `Average total amount`,
  chat_component_header_text: `Hi {{username}}! how can I help you?`,
  try_dosti: `Try Dosti`,
  ask_ai: `Ask AI`,
  ask_ai_about_documents: `Ask AI about documents`,
  suggested_prompts: `Suggested`,
  "How many documents have I uploaded so far?":
    "How many documents have I uploaded so far?",
  "What are the names of the top vendors?":
    "What are the names of the top vendors?",
  "What is the total amount of each document?":
    "What is the total amount of each document?",

  // Users
  delete_user: `Delete user`,
  user_deleted: `User deleted successfully`,
  deactivated_successfully: `Deactivated successfully`,
  activated_successfully: `Activated successfully`,
  delete_user_warning: `Are you sure you want to delete user '{{username}}'?`,
  isActive: `Is Active`,
  deactivate_user: `Deactivate user`,
  activate_user: `Activate user`,
  deactivate_user_warning: `Are you sure you want to deactivate user '{{userName}}'?`,
  activate_user_warning: `Are you sure you want to activate user '{{userName}}'?`,
  copyright_message: `All rights reserved`,
  field_must_be_a_number: `Field must be a number`,
  delete_invitation: `Delete invitation`,
  delete_invitation_warning: `Are you sure you want to delete invitation send to {{username}}?`,
  invitation_deleted: `Invitation deleted successfully`,
  enable_testing_mode: `Enable testing mode`,
  disable_testing_mode: `Disable testing mode`,
  testing_mode_enabled: `Testing mode enabled`,
  testing_mode_disabled: `Testing mode disabled`,
  enable_testing_mode_warning: `Are you sure you want to enable testing mode for user {{username}}?`,
  disable_testing_mode_warning: `Are you sure you want to disable testing mode for user {{username}}?`,
  test_mode: `Test mode`,
  // Self Training Reports
  self_training_reports: `Self training report`,
  start_date: `Start Date`,
  end_date: `End Date`,
  start_date_is_required: `Start date is required`,
  end_date_is_required: `End date is required`,
  requests: `Requests`,
  tag_name: `Tag name`,
  request_status: `Status`,
  req_sent_date: `Request sent date`,
  new_model: `New model`,
  pending_requests: `Pending requests`,
  document_ids: `Document IDs`,
  filters: `Filters`,
  end_date_cannot_be_of_future_date: `End date cannot be of future date`,
  export_as_csv: `Export as CSV`,
  // Export Configuration
  consider_document_when: `Consider document when`,
  isConsiderDocumentInitialProcessed: `Document is initially processed`,
  isConsiderDocumentReviewed: `Document is reviewed`,
  includeOrganizationDocuments: `Include organization documents`,
  isConsiderDocumentPaymentReceived: ` Document payment is recieved`,
  atleast_one_option_must_be_checked: `Atleast one option is required`,
  document_consideration: `Document consideration`,
  view_docIds: `View document IDs`,
  // Document Listing Page
  to_delete_documents: `Documents {{documents}} cannot be deleted because they are already created in ERP, kindly deselct them and try again.`,
  information: `Information`,
  review_document: `Review document`,
  view_discrepancy: `View discrepancy`,
  field_operator: `Field operator`,

  // Dashboard
  due_amount: "Due amount",
  paid_amount: "Paid amount",
  "Review document": "Review document",
  documents_processed: `Documents processed`,
  document_is_deleted: `Document is deleted`,
  document_is_integrated: `Document is integrated`,
  review_document_payment_paid: `Payment has been paid`,
  review_document_payment_processing: `Payment is being processed`,
  current_month: `Current month`,
  // Mapping V2
  upload_requests: `Upload requests`,
  download_requests: `Download requests`,
  no_upload_requests_found: `No upload requests found`,
  confirm_upload_request: `Are you sure you want to upload this CSV`,
  completed_on: `Completed on`,
  upload_bulk_configuration: `Upload bulk configuration`,
  upload_mapping_rquest_inprocess: `Mapping request in process`,
  upload_mapping_not_valid: `Mapping not valid`,
  upload_mapping_csv_format_allowed: `Mapping CSV format allowed`,
  upload_mapping_organization_not_found: `Mapping organization not found`,
  mapping_download_request_inprocess: `Download request in process`,
  download_mapping_not_found: `Mapping not found`,
  download_mapping_status_failed: `Mapping status failed`,
  Uploaded: "Uploaded",
  add_individual_configuration: `Add individual configuration`,
  no_download_requests_found: `No download requests found`,
  download_request_sent: `Download request sent`,
  download_bulk_configuration: `Download bulk configurations`,
  add_individual_mapping: `Add individual mapping`,
  upload_bulk_mapping: `Upload bulk mapping`,
  download_bulk_mapping: `Download mapping`,
  generated: `Generated`,
  processing: `In process`,
  selected_documents_already_approved_or_rejected: `Selected documents are already approved or rejected`,
  expectedvalue_bulk_import_duplicate_label: `duplicate label found`,
  value_is_required: `Value is required`,
  assign_workflow: `Assign workflow`,
  errors: {
    "Server Error": `Server Error`,
    "document type 'Invoice' has duplicate fields.": `document type 'Invoice' has duplicate fields.`,
  },

  workflows_screen: {
    title: "Discover the power of Workflows",
    workflows: "Workflows",
    add: "Add Workflow",
    subtitle: "Invoices Workflow",
    alertActivateMessage: "Activated successfully",
    activate: "Activate",
    alertDeactivateMessage: "Deactivated successfully",
    deactivate: "Deactivate",
    save: "Save",
    invoice: "Invoice",
    total_amount: "Total Amount",
    email_to_approve: "Email to Approve",
    greater_than: "Greater Than",
    greater_than_or_equal_to: "Greater Than or Equal To",
    dashboard: "Dashboard",
  },
  approval_status: {
    approvalRequired: "Approval Required",
    approved: "Approved",
    rejected: "Rejected",
    reviewRequired: "Review Required",
  },

  approval_screen: {
    review: "Review",
    title: "Documents pending approval",
    filename: "File Name",
    totalAmount: "Total Amount",
    status: "Status",
    all: "All",
    invoices: "Invoices",
    reciepts: "Reciepts",
    processedDate: "Processed Date",
    yes: "Yes",
    no: "No",
    action: "Action",
    approve: "Approve",
    reject: "Reject",
    alertRejectMessage: "Are you sure you want to reject this document?",
    alertRejectTitle: "Reject Document",
    alertApproveMessage: "Are you sure you want to approve this document?",
    alertApproveTitle: "Approve Document",
    workflowName: `Workflow name`,
    alertApproveDocsTitle: `Approve documents`,
    alertRejectDocsTitle: `Reject documents`,
    alertApproveDocsMessage: `Are you sure you want to approve these documents?`,
    alertRejectDocsMessage: `Are you sure you want to reject these documents?`,
  },
  configure: "Configure",
  "approve / reject": "Approve / Reject",
  verification: {
    title: "Verify your account",
    success: "Your account has been verified successfully",
    error: "Your account could not be verified",
    loading: "Verifying your account ...",
    retry: "Resend verification email",
  },
  "account is already verified": "Account is already verified",
  "verification link is expired. please re-generate verification link.":
    "Verification link is expired. Please re-generate verification link.",
  navbar_account: {
    trial: "Trial",
    pro_trial: "Pro Trial",
    pro: "Pro",
    Pro: "Pro",
    days_left: "Days left",
    of: "of",
    Unverified: "Account Not Verified",
    starter: "Starter",
    Starter: "Starter",
    basic: "Basic",
    Basic: "Basic",
    tooltip_text: "Verify your account through link sent on email",
  },
  Enterprise: "Enterprise",
  Basic: "Basic",
  reviewToolbar: {
    previous_page: "Previous page",
    next_page: "Next page",
    zoomin: "Zoom in",
    zoomout: "Zoom out",
    deselect_multiple: "Deselect multiple",
    select_multiple: "Select multiple",
    Rotate_document: "Rotate document",
    done: "Done",
    hide_info: "Hide Info",
    show_info: "Show Info",
  },

  delsol_output_connector: {
    factoryCode: `Factory Code`,
    customerCode: `Customer Code`,
    database: `Database`,
    password: `Password`,
  },

  ftp_input_connector: {
    FtpHost: "Host",
    FtpPort: "Port",
    FtpUserId: "User Id",
    FtpPassword: "Password",
    FtpPath: "Path",
    IsTLS: "Is TLS",
    IsSFTP: "Is SFTP",
  },
  ftp_upload_output_connector: {
    FtpHost: "Host",
    FtpPort: "Port",
    FtpUserId: "User Id",
    FtpPassword: "Password",
    FtpPath: "Path",
    IsTLS: "Is TLS",
    IsSFTP: "Is SFTP",
  },
  ftp_output_connector: {
    FtpHost: "Host",
    FtpPort: "Port",
    FtpUserId: "User Id",
    FtpPassword: "Password",
    FtpPath: "Path",
    IsTLS: "Is TLS",
    IsSFTP: "Is SFTP",
    DisableIndividualDocumentIntegration:
      "Disable individual document integration",
    DisableIndividualDocumentIntegration_hint:
      "When enabled, it will stop individual document integration. It's useful if only export CSV is required.",
  },
  ftp_download_input_connector: {
    FtpHost: "Host",
    FtpPort: "Port",
    FtpUserId: "User Id",
    FtpPassword: "Password",
    FtpPath: "Path",
  },
  businesscentral_output_connector: {
    tenant: "Tenant",
    clientId: "Client Id",
    clientSecret: "Client Secret",
    environmentName: "Environment Name",
    companyId: "Company Id",
    apiPublisher: "Api Publisher",
    apiGroup: "Api Group",
    apiVersion: "Api Version",
  },
  holded_output_connector: {
    APIKey: "API Key",
    apiKey: "API Key",
    enableLegacyHoldedIntegration: "Enable Legacy Holded Integration",
    enableV2: "Enable V2",
  },

  "Extracting Documents":
    "Extracting {{count}} document{{s}}. We will notifiy you when the extraction is finished. ",

  cardonar_output_connector: {
    username: `Username`,
    password: `Password`,
    authEndpoint: `Authentication URL`,
    documentEndpoint: `Document URL`,
  },
  asap_output_connector: {
    userName: `Username`,
    password: `Password`,
    authEndpoint: `Authentication URL`,
    documentEndpoint: `Document URL`,
  },

  volotea_output_connector: {
    ApiKey: `API Key`,
    BaseUrl: `Base URL`,
    AccessKey: `Access key`,
    SecretKey: `Secret key`,
    ServiceName: `Service name`,
    AwsRegion: `AWS region`,
    InvoiceCreateUrl: `Invoice create url`,
  },

  comforsa_output_connector: {
    UserName: `Username`,
    Password: `Password`,
    Company: `Company`,
    Language: `Language`,
    BaseUrl: `Base URL`,
  },

  odoo_output_connector: {
    apiurl: "API URL",
    dbname: "Database Name",
    username: "Username",
    password: "Password",
  },

  sagex3_output_connector: {
    poolid: `Pool ID`,
    codelanguage: `Code language`,
    username: "Username",
    password: "Password",
  },

  "Company default language": "Company default language",

  "Download from FTP": "Download from FTP",
  "Duplicate workflow": "Duplicate workflow",
  "Delete workflow": "Delete workflow",
  "View workflow": "View workflow",
  "You cannot connect more than one output-connector.": `You cannot connect more than one output connector.`,
  "Hello ": "Hello ",
  Yesterday: "Yesterday",
  "This Week": "This Week",
  "Last Week": "Last Week",
  "This Month": "This Month",
  "Last Month": "Last Month",
  "This Quarter": "This Quarter",
  "Last Quarter": "Last Quarter",
  "This Year": "This Year",
  "Last Year": "Last Year",
  All: "All",
  Today: "Today",
  "Integrations status": "Integrations status",
  "Processing overview": "Processing overview",
  View: "View",
  "documents / month": "documents / month",
  "Your current plan is ": "Your current plan is ",
  enterprise: "Enterprise",
  "Not convinced with your current plan?":
    "Not convinced with your current plan?",
  "View our offers": "View our offers",
  "View documents": "View documents",
  "The document extraction is completed":
    "The document extraction is completed",
  "See more": "See more",
  "Documents pending Approval": "Documents pending Approval",
  "Dost Academy": "Dost Academy",
  "My account": "My account",
  "Users management": "Users management",
  Logout: "Logout",
  Subscribe: "Subscribe",
  Cancel: "Cancel",
  browse: "Browse",
  "Suppliers Management": "Suppliers management",
  "Add a new supplier": "Add a new supplier",
  "You don't have any suppliers yet": "You don't have any suppliers yet",
  "Add Supplier": "Add Supplier",
  "Supplier Information": "Supplier information",
  DiscrepancyDetected: "Discrepancy Detected",
  CustomerCompanyName: "Customer Company Name",
  DocumentClass: "Document Class",
  discrepancies_: "discrepancies",
  "document limit exceed": "document limit exceed",
  "documents pending approval": "documents pending approval",
  "new successfull extraction": "new successfull extraction",
  "Awaiting Extraction...": "Awaiting Extraction...",
  copy: "copy",
  copied: "copied",
  Close: "Close",
  "View Document": "View Document",
  "Download CSV": "Download CSV",
  "Download JSON": "Download JSON",
  "View original": "View original",
  "Download Invoice(s)": "Download Invoice(s)",
  "Download Invoice": "Download Invoice",
  "Download document": "Download document",
  "View document": "View document",
  "Workflow settings": "Workflow settings",
  "View all documents": "View all documents",
  "New workflow": "New workflow",
  "Workflows configured": "Workflows configured",
  "Workflow name": "Workflow name",
  "Put a name for your workflow": "Put a name for your workflow",
  And: "And",
  "If the variable": "If the variable",
  "Variable selected": "Variable selected",
  Is: "Is",
  "Condition selected": "Condition selected",
  "to the value": "to the value",
  Value: "Value",
  Approvers: "Approvers",
  "Add a new email": "Add a new email",
  "See documents related": "See documents related",
  "Conditions configured": "Conditions configured",
  Conditions: "Conditions",
  Condition: "Condition",
  "Created on": "Created on",
  "Document Name": `Document name`,
  "Processed on": `Processed on`,
  "Status in workflow": `Status in workflow`,
  "Output data": `Output data`,
  "Document configuration": `Document configuration`,
  Mandatory: `Mandatory`,
  "Create custom field": `Create custom field`,
  "Input format configuration": `Input format configuration`,
  "Add Vendor Configuration": `Add Vendor Configuration`,
  "Create vendor configuration": `Create vendor configuration`,
  "Create customer configuration": `Create customer configuration`,
  "Input Mapping Configuration": `Input Mapping Configuration`,
  "Input Mapping": `Input Mapping`,
  download_template: `Download template`,
  test: "test",
  "Entries per page:": "Entries per page:",
  "Document status": "Document status",
  "Document name": "Document name",
  "ERP integrated": "ERP integrated",
  "Approval status": "Approval status",
  "End date": "End date",
  "Start date": "Start date",
  approved: "Approved",
  rejected: "Rejected",
  pending: "Pending",
  "Connector failed to connect. Please try again with valid credentials.":
    "Connector failed to connect. Please try again with valid credentials.",
  "Document reviewed": "Document reviewed",
  "Search ...": `Search ...`,
  "Allow review document": `Allow review document`,
  "Items per page": `Items per page`,
  of_: `of`,
  Dashboard: `Dashboard`,
  "This is a required field": `This is a required field`,
  Messages: `Messages`,
  Fields: `Fields`,
  "Culture setting": `Culture setting`,
  "Format setting": `Format setting`,
  Other: `Other`,
  "Document Type": `Document Type`,
  "Default Configuration": `Default Configuration`,
  "Mapping Configuration": `Mapping configuration`,
  "Export Configuration": `Export configuration`,
  "Developers settings": `Developers settings`,
  "Developers Section": "Developers Section",
  URL: "URL",
  Key: "Key",
  API: "API",
  "Generate Api Secret": "Generate Api Secret",
  "developer-docs":
    "To access the developer documentation, please click <link1></link1> .",
  "Developer settings": "Developer settings",
  Documentation: "Documentation",
  here: "here",
  "View table": "View table",
  "Delete table": "Delete table",
  "Document review": "Document review",
  Download: "Download",
  csv: "CSV",
  excel: "excel",
  XML: "XML",
  text: "text",
  JSON: "JSON",
  zip: "zip",
  "csv(s)": "csv(s)",
  "excel(s)": "excel(s)",
  "XML(s)": "XML(s)",
  "text(s)": "text(s)",
  "JSON(s)": "JSON(s)",
  "zip(s)": "zip(s)",
  "Export original(s)": "Export original(s)",
  "Copy link": "Copy link",
  "documents are uploaded till now": "documents are uploaded till now",
  "Are you sure you want to delete this supplier?": `Are you sure you want to delete this supplier?`,
  hours: `hours`,
  Equals: "Equals",
  Like: "Like",
  like: "Like",
  StartsWith: "Starts with",
  startsWith: "Starts with",
  EndWith: "Ends with",
  endWith: "Ends with",
  NotStartsWith: "Not starts with",
  notStartsWith: "Not starts with",
  NotEquals: "Not equals",
  NotLike: "Not like",
  "Network Error": "Network Error",
  send: "Send",
  Mapping: "Mapping",
  Export: "Export",
  "ERP Sync": "ERP Sync",
  Suppliers: "Suppliers",
  Save: "Save",
  VendorCompanyName: "Vendor Company Name",
  TotalwithoutTax: "Total without Tax",
  TotalTax: "Total Tax",
  VendorCompanyID: "Vendor Company ID",
  VendorCompanyAddress: "Vendor Company Address",
  InvoiceID: "Invoice ID",
  InvoiceDate: "Invoice Date",
  CustomerCompanyID: "Customer Company ID",
  CustomerCompanyAddress: "Customer Company Address",
  PONumber: "PO Number",
  Filters: "Filters",
  "Document Fields": "Document Fields",
  "Field name": "Field name",
  Add: "Add",
  "Clear all": "Clear all",
  "Add an item": "Add an item",
  Subtotal: "Subtotal",
  previous_page: "Previous page",
  next_page: "Next page",
  Line: "Line",
  "Are you sure you want to delete this column?": `Are you sure you want to delete this column?`,
  "Are you sure you want to delete this table?": `Are you sure you want to delete this table?`,
  "Delete this table?": `Delete this table?`,
  "Deleting this column will remove all the rows in this table.": `Deleting this column will remove all the rows in this table.`,
  Continue: "Continue",
  Back: "Back",
  "Coming soon!": "Coming soon!",
  source: "Source",
  isFailed: "Failed",
  failureReason: "Failure Reason",
  documentStatus: "Document Status",
  processingSteps: "Processing Steps",
  workflowName: "Workflow Name",
  reviewedBy: "Reviewed By",
  approvalStatus: "Approval Status",
  approverOrRejector: "Approver/Rejector",
  "Document Upload": "Document Upload",
  "Document Processed": "Document Processed",
  "Workflow Execute": "Workflow Execute",
  "Workflow Approve/Reject": "Workflow Approve/Reject",
  "Document Integrate": "Document Integrate",
  "Document Review": "Document Review",
  "Document History": "Document History",
  "View history": "View history",
  "Add Field": "Add Field",
  field_type: "Field Type",
  Name: "Name",
  "Data type": "Data type",
  Actions: "Actions",
  Number_Fractional: "Number (Fractional)",
  Date: "Date",
  Text: "Text",
  Number_Whole: "Number (Whole)",
  Header: "Header",
  TableProperty: "Table Property",
  "To add a new field, please enter the field name in the text field below.": `To add a new field, please enter the field name in the text field below.`,
  "Table name": "Table name",
  "No PDF file specified": "No PDF file specified",
  "Error loading PDF": "Error loading PDF",

  roleName: {
    OrgAdmin: "Admin",
    OrgUser: "User",
  },
  sectionName: {
    Documents: "Documents",
    MappingConfigurations: "Mapping Configurations",
    DocumentConfigurations: "Document Configurations",
    ExportConfigurations: "Export Configurations",
    UserManagement: "User Management",
    Suppliers: "Suppliers",
    RoleManagement: "Role Management",
    Integrations: "Integrations",
    WorkflowManagement: "Workflow Management",
    WorkflowApproval: "Workflow Approval",
    DeveloperSettings: "Developer Settings",
    CatalogManagement: "Catalog Management",
    Payments: "Payments",
    Subscription: "Subscription",
    ExportTemplates: "Export Templates",
    Customers: "Customers",
  },
  permissions: {
    Documents_Read: "Documents read",
    Documents_Write: "Documents write",
    MappingConfigurations_Read: "Mapping configurations read",
    MappingConfigurations_Write: "Mapping configurations write",
    DocumentConfigurations_Read: "Document configurations read",
    DocumentConfigurations_Write: "Document configurations write",
    ExportConfigurations_Read: "Export configurations read",
    ExportConfigurations_Write: "Export configurations write",
    UserManagement_Read: "User management read",
    UserManagement_Write: "User management write",
    Suppliers_Read: "Suppliers read",
    Suppliers_Write: "Suppliers write",
    RoleManagement_Read: "Role management read",
    RoleManagement_Write: "Role management write",
    Integrations_Read: "Integrations read",
    Integrations_Write: "Integrations write",
    WorkflowManagement_Read: "Workflow management read",
    WorkflowManagement_Write: "Workflow management write",
    WorkflowApproval_Read: "Workflow approval read",
    WorkflowApproval_Write: "Workflow approval write",
    DeveloperSettings_Read: "Developer settings read",
    DeveloperSettings_Write: "Developer settings write",
    CatalogManagement_Read: "Catalog management read",
    CatalogManagement_Write: "Catalog management write",
    Payments_Read: "Payments read",
    Payments_Write: "Payments write",
    Subscription_Read: "Subscription read",
    ExportTemplates_Read: "Export templates read",
    ExportTemplates_Write: "Export templates write",
  },
  "Fields configuration": "Fields configuration",
  "Field name should be unique": "Field name should be unique",
  "Field name cannot contain space": "Field name cannot contain space",
  "Field name should start with capital letter":
    "Field name should start with capital letter",
  "Field name should not contain special characters":
    "Field name should not contain special characters",
  "Field name and Table name should not be same": `Field name and Table name should not be same`,
  "Field Name is required": `Field Name is required`,
  "Data type should be same": `Data type should be same`,
  "Data type is required": `Data type is required`,
  "Table name is required": `Table name is required`,
  "Table name should be plural": `Table name should be plural`,
  "Table name cannot contain space": `Table name cannot contain space`,
  "Table name should start with capital letter": `Table name should start with capital letter`,
  "Table name should not contain special characters": `Table name should not contain special characters`,
  "Document Discrepancy": `Document Discrepancy`,
  "Document detail": `Document detail`,
  "Related documents": `Related documents`,
  "No related documents found.": `No related documents found.`,
  "no documents": `No documents`,
  "No items": `No items`,
  "No items with discrepancies found.": `No items with discrepancies found.`,
  "No documents Found": "No documents Found",
  "Access Forbidden": `Access Forbidden`,
  "You don't have permission to access this page": `You don't have permission to access this page`,
  "Go to homepage": `Go to homepage`,
  "The page you are looking for might have been removed or its name changed or is temporarily unavailable.": `The page you are looking for might have been removed or its name changed or is temporarily unavailable.`,
  "Page not found": `Page not found`,
  User: `User`,
  Role: `Role`,
  "Edit role": `Edit role`,
  "Default roles": `Default roles`,
  "Custom roles": `Custom roles`,
  "Add new role": `Add new role`,
  "Role configuration": `Role configuration`,

  "Create a new role": `Create a new role`,
  "Enter role name": `Enter role name`,
  "Access is granted for": `Access is granted for`,
  Section: `Section`,
  Read: `Read`,
  Write: `Write`,
  "Discard changes": `Discard changes`,
  "Save changes": `Save changes`,
  "Read and write": `Read and write`,
  "Role already exists": `Role already exists`,
  "Role name is required": `Role name is required`,
  "Permissions are required": `Permissions are required`,
  "Read / write": `Read / write`,
  "Select at least one permission": `Select at least one permission`,
  "Delete this role?": `Delete this role?`,
  "Are you sure you want to delete this role?": `Are you sure you want to delete this role?`,
  Type: `Type`,
  "Role name": `Role name`,
  Permissions: `Permissions`,
  "User updated successfully": `User updated successfully`,
  "Email must be a valid email address": `Email must be a valid email address`,
  "Role is required": `Role is required`,
  "Field expected values": `Field expected values`,
  "Add expected value": `Add expected value`,
  Description: `Description`,
  Edit: `Edit`,
  "Expected Values": `Expected Values`,
  "Expected value already exists please add unique description and value.": `Expected value already exists please add unique description and value.`,
  field_title: "{{field}}'s values",
  "Add predefined value": `Add predefined value`,
  add_predefined_values: `Add predefined values`,
  upload_bulk_predefined_values: `Upload bulk values`,
  add_individual_predefined_value: `Add predefined value`,
  expeectedvalue_upload_csv_format_allowed: `Please upload a valid CSV file in the allowed format`,
  expeectedvalue_upload_file_notvalid: `The uploaded file is not valid. Please upload a valid file`,
  expeectedvalue_upload_only_required_lableandvalue: `Only the required label and value fields are allowed for upload.`,
  bulk_predefined_values_upload: `Upload CSV for bulk Predefined values`,
  expected_value_first_text: `1. Download our sample CSV template for predefined values.`,
  "Predefined values": `Predefined values`,
  Label: `Label`,
  "Document Messages": `Document Messages`,
  Notifications: `Notifications`,
  "Mark all as read": `Mark all as read`,
  "No new notifications": `No new notifications`,
  "Mark this notification as read": "Mark this notification as read",
  "Download Document": `Download Document`,
  "Delete this document?": `Delete this document?`,
  "Are you sure you want to delete this document?":
    "Are you sure you want to delete this document?",
  "Add tag for self-training": `Add tag for self-training`,
  "Tag name not found in document": `Tag name not found in document`,
  "Note: Tag name should be unique like vendor id, vendor name, customer id, customer name and mentioned in document’s content.": `Note: Tag name should be unique like vendor id, vendor name, customer id, customer name and mentioned in document’s content.`,
  "Tag name": `Tag name`,
  "Enter tag name": `Enter tag name`,
  "There is no tag attached with this document, if you wish to make this part of self-training please enter tag name.": `There is no tag attached with this document, if you wish to make this part of self-training please enter tag name.`,
  "Tag name is required": `Tag name is required`,
  "Ignore & save": `Ignore & save`,
  documentName: `Document Name`,
  documentId: `Document Id`,
  uploadedBy: `Uploaded By`,
  "Original document already integrated.": `Original document already integrated.`,
  "Document has been integrated.": `Document has been integrated.`,
  "You have unsaved changes. Do you want to save them?": `You have unsaved changes. Do you want to save them?`,
  doc_configuration_unsaved_changes_warning:
    "Changes you made may not be saved. Are you sure you want to leave this page?",

  "Document has been updated/changed": `Document has been updated/changed`,
  "Document data has been updated/changed": `Document data has been updated/changed`,
  "Some fields need to be reviewed. Show info for details.": `Some fields need to be reviewed. Show info for details.`,
  "Atleast one polygon should be updated": `Atleast one polygon should be updated`,
  "To enable self-training, atleast one polygon should be updated.": `To enable self-training, atleast one polygon should be updated.`,
  "Note: Removing tag from this document will remove it from other documents as well.": `Note: Removing tag from this document will remove it from other documents as well.`,
  "Are you sure you want to remove this tag?": `Are you sure you want to remove this tag?`,
  "Remove tag": `Remove tag`,
  "Failed to remove tag": `Failed to remove tag`,
  "Tag not found": `Tag not found`,
  AccountReceivable: `Account Receivable`,
  AccountPayable: `Account Payable`,
  account_class_field_missing: `Account class field is missing`,
  registrationError: `Registration ID is required`,
  registrationSpecialChrError: `Registration ID should not contain special characters`,
  registrationSpaceError: `Registration ID should not contain spaces`,
  "Signing certificate overview": `Signing certificate overview`,
  "Is valid": `Is valid`,
  Issuer: `Issuer`,
  Expiry: `Expiry`,
  january: `January`,
  february: `February`,
  march: `March`,
  april: `April`,
  may: `May`,
  june: `June`,
  july: `July`,
  august: `August`,
  september: `September`,
  october: `October`,
  november: `November`,
  december: `December`,
  "Accounting period": `Accounting period`,
  "This period is used for accounting purposes.": `This period is used for accounting purposes.`,
  "Open date": `Open date`,
  "Close period": `Close period`,
  "Close date": `Close date`,
  "Are you sure you want to close the accounting period? Once the period is closed, all documents for that period will be non-editable.": `Are you sure you want to close the accounting period? Once the period is closed, all documents for that period will be non-editable.`,
  "Close accounting period": `Close accounting period`,
  "View period": `View period`,
  "Failed to close period": `Failed to close period`,
  "Period closed successfully": `Period closed successfully`,
  review_accountingperiod_closed:
    "Document is non-editable as the accounting period is closed.",
  "Document can not be updated because the accounting period is closed.": `Document can not be updated because the accounting period is closed.`,
  "Include deleted documents": `Include deleted documents`,
  validation_accountclass_required: `Account class is required`,
  "Document has been deleted.": `Document has been deleted.`,
  Deleted: "Deleted",
  InvoiceDNMatching_DeliveryNoteNotFound: "Delivery note not found.",
  InvoiceDNMatching_ItemCountNotMatched: "Item count does not match.",

  InvoiceDNMatching_DeliveryNoteItemsNotFound: "Delivery note items not found.",
  InvoiceDNMatching_InvoiceItemDescriptionAndCodeNotAttached:
    "Invoice item description and code not attached.",
  InvoiceDNMatching_InvoiceItemNotFoundInDN:
    "Invoice item not found in delivery note.",
  InvoiceDNMatching_ItemQuantityGreaterThan:
    "Item quantity is greater than expected.",
  InvoiceDNMatching_ItemQuantityLessThan:
    "Item quantity is less than expected.",
  InvoiceDNMatching_PaymentTerms_DueDate: "Payment terms or due date mismatch.",
  InvoiceDNMatching_TotalAmountMismatch: "Total amount mismatch.",
  InvoicePOMatching_AmountNotMatch:
    "Amount does not match with the purchase order.",
  InvoicePOMatching_PONumberNotFoundInInvoice:
    "Purchase order number not found in invoice.",
  InvoicePOMatching_PONumberNotFound: "Purchase order number not found.",
  PODNMatching_PONumberNotFound: "Purchase order number not found.",
  PODNMatching_PONotFound: "Purchase order not found.",

  PODNMatching_ItemCountNotMatched:
    "Item count does not match with purchase order.",

  PODNMatching_POItemsNotFound: "Purchase order items not found.",
  PODNMatching_DNItemDescriptionAndRefrenceCodeNotFound:
    "Delivery note item description and reference code not found.",
  PODNMatching_DelveryNoteItemNotFoundInPO:
    "Delivery note item not found in purchase order.",
  PODNMatching_DeliveryNoteItemQuantityZero:
    "Delivery note item quantity is zero.",
  PODNMatching_DeliveryNoteItemQuantityGreaterthan:
    "Delivery note item quantity is greater than expected.",
  PODNMatching_DeliveryNoteItemQuantityLessThan:
    "Delivery note item quantity is less than expected.",
  "Discrepancy configuration": `Discrepancy configuration`,
  Discrepancy: `Discrepancy`,
  "Invoice DN Matching": `Invoice <> Delivery note`,
  "Invoice PO Matching": `Invoice <> Purchase order`,
  "PO DN Matching": `Purchase order <> Delivery note`,
  "Custom Discrepancies": `Custom Discrepancies`,
  CidalPOItemPriceMatchingStrategy:
    "Match PO item price with catalog item price.",
  IASOInvoiceDNStrategy: "Match Invoice Total amount with DN total amount.",
  IASOInvoicePOStrategy: "Match Invoice Total Amount with PO Total Amount.",
  InvoiceSimultedInvoiceMatching:
    "Match Invoice Amount, and Consumption with Simulated Invoice.",
  previous: "Previous",
  "Upload with QR code": `Upload with QR code`,
  "Document Upload via QR": `Document Upload via QR`,
  "Scan the QR code to instantly upload your documents with the Dost App": `Scan the QR code to instantly upload your documents with the Dost App.`,

  "Download App (Android)": `Download App (Android)`,
  Customers: `Customers`,
  "Customers management": `Customers management`,
  "Add a new customer": `Add a new customer`,

  "Add Customer": `Add Customer`,
  "You don't have any customers yet": `You don't have any customers yet`,
  customer_screen: {
    updated: "Customer updated successfully",
    notCreated: "Customer not created",
    created: "Customer created successfully",
    customerUrl: "Customer URL",
    edit_customer: "Edit customer",
    delete_customer: "Delete customer",
    delete_customer_dialog: "Delete customer",
    deleted: "Customer deleted successfully",
    reset_successful: "Customer onboarding reset successfully",
    reset_approval: `Are you sure you want to reset onboarding of this supplier?`,
    reset_onboarding: `Reset onboarding`,
    reset_customer_onboarding: `Reset cupplier onboarding`,
  },
  "Customer information": "Customer information",
  customers: "customers",
  "Insert new row": `Insert new row`,
  "Enter row number": `Enter row number`,
  "Optional: Insert at row (1 to )": `Optional: Insert at row (1 to {{total}})`,
  Insert: `Insert`,
  "Add Row": `Add Row`,
  "Remove column": `Remove column`,
  "Company ID": `Company ID`,
  TariffCode: `Tariff Code`,
  OriginCountry: `Origin Country`,
  Weight: `Weight`,
  ContactEmail: `Contact Email`,
  DeliveryAddress: `Delivery Address`,
  PaymentMethod: `Payment Method`,
  TotalDiscount: `Total Discount`,
  DeliveryDate: `Delivery Date`,
  ShippingAddress: `Shipping Address`,
  PaymetMethod: `Payment Method`,
  ShippingMethod: `Shipping Method`,
  DocumentNumber: `Document Number`,
  DocumentDate: `Document Date`,
  DocumentId: `Document ID`,
  UserId: `User ID`,
  Environment: `Environment`,
  Items: "Items",
  PaymentTerms: "Payment Terms",
  Accounts: "Accounts",
  AmountPercentage: "Amount Percentage",
  GLAccount: "GL Account",
  CostCenter: "Cost Center",
  RefCriteria: "Ref Criteria",
  "I'm not sure": "I'm not sure",
  Industry: "Industry",
  "Select Industry": "Select Industry",
  "Advertising & Marketing": "Advertising & Marketing",
  Agriculture: "Agriculture",
  Airline: "Airline",
  Automotive: "Automotive",
  Banking: "Banking",
  Biotech: "Biotech",
  Construction: "Construction",
  Cosmetics: "Cosmetics",
  Distribution: "Distribution",
  Education: "Education",
  Energy: "Energy",
  Finance: "Finance",
  Health: "Health",
  Hospitality: "Hospitality",
  "IT & technology": "IT & technology",
  Manufacturing: "Manufacturing",
  Mining: "Mining",
  "Professional services": "Professional services",
  "Real estate": "Real estate",
  Retail: "Retail",
  Telecommunications: "Telecommunications",
  Tourism: "Tourism",
  Transport: "Transport",
  CFO: "CFO",
  "Financial Manager": "Financial Manager",
  "Finance Director": "Finance Director",
  CTO: "CTO",
  CIO: "CIO",
  "Technology Manager": "Technology Manager",
  "Technology Director": "Technology Director",
  Accountant: "Accountant",
  Freelancer: "Freelancer",
  "Phone number": "Phone number",
  "Search country": "Search country",
  "Phone Number is required": "Phone Number is required",
  "Invalid phone number": "Invalid phone number",
  onboarding_status: "Onboarding Status",
  "Copied !": "Copied !",
  "Click to copy": "Click to copy",
  Payload: "Payload",
  payload: "Payload",
  NotStartWith: "Not starts with",
  StartWith: "Starts with",
  "Add single / multiple IDs by pressing 'Enter' after each one.": `Add single / multiple IDs by pressing 'Enter' after each one.`,
  "General supplier or supplier not providing on-site services/materials at a festival.": `General supplier or supplier not providing on-site services/materials at a festival.`,
  "Supplier linked to a festival for providing on-site services/materials.": `Supplier linked to a festival for providing on-site services/materials.`,
  "Supplier name": `Supplier name`,
  "Company name": `Company name`,
  Nationality: `Nationality`,
  "Tax identification number (NIF)": `Tax identification number (NIF)`,
  "Registered address": `Registered address`,
  "Email address": `Email address`,
  "T&C": `T&C`,
  "Other conditions": `Other conditions`,
  "Company name is required": "Company name is required",
  "Type is required": "Type is required",
  "Nationality is required": "Nationality is required",
  "Tax identification number (NIF) is required":
    "Tax identification number (NIF) is required",
  "Registered address is required": "Registered address is required",
  "Terms and conditions is required": "Terms and conditions is required",
  "Other conditions is required": "Other conditions is required",
  "Terms and conditions": "Terms and conditions",
  "T & C": "T & C",
  "Write your terms and conditions ...": "Write your terms and conditions ...",
  "Write your other conditions ...": "Write your other conditions ...",
  "Company ID is required": `Company ID is required`,
  selfTrainingReport: {
    status: {
      Pending: "Pending",
      Completed: "Completed",
      Failed: "Failed",
    },
  },
  "Netsuite configurations": `Netsuite configurations`,
  "Last sync date": `Last sync date`,
  "Sync Now": `Sync Now`,
  "Entities being synced": `Entities being synced`,
  "Enable Vendor Sync For Supplier Portal": `Enable vendor sync for supplier portal`,
  "Enable PO Sync For Discrepancy": `Enable PO sync for discrepancy`,
  "Enable item creation": "Enable item creation",
  "Configure TBA": "Configure TBA",
  "Token secret": "Token secret",
  "Token key": "Token key",
  "Consumer secret": "Consumer secret",
  "Configure TBA for SOAP & Restlets": "Configure TBA for SOAP & Restlets",
  "Consumer key": "Consumer key",
  "Synced successfully": `Synced successfully`,
  "Updated successfully": `Updated successfully`,
  "Failed to update": `Failed to update`,
  "Type keywords for description or value..":
    "Type keywords for description or value..",
  "Sync request sent": "Sync request sent",
  "Show more": "Show more",
  "Show less": "Show less",
  Complete: "Complete",
  Incomplete: "Incomplete",
  "Business Central configurations": "Business Central configurations",
  "Impersonate supplier": "Impersonate supplier",
  "Impersonate Url": "Impersonate Url",
  expectedfield_parentfield_value_required: `Please select {{parentField}} to populate {{field}}`,
  connector_not_connected: "Connector not connected",
  connector_being_synced: "Connector being synced",
  connector_being_sycned: "Connector being synced",
  syncing_error_token_invalid: "invalid access token, please re-connect",
  "There were some issues encountered during the synchronization process":
    "There were some issues encountered during the syncing process.",

  invoice_payment: "Invoice payments",
  workflow_updated_successfully: "Workflow Updated Successfully",
  "Edit workflow": "Edit workflow",

  custom_fields_configuration: "Custom fields configuration",
  add_custom_fields: "Add custom fields",
  document_config_field_name: "Document config field name",
  String: "String",
  Reference: "Reference",
  Boolean: "Boolean",
  document_config_field_name_is_required:
    "Document config field name is required",
  "Error message": "Error message",
  "view error": "view error",
};

export default en;
