const workflows = {
  workflow_history: `Workflow history`,
  workflow_updated: `Workflow updated`,
  new_workflow: `New workflow`,
  old_workflow: `Old workflow`,
  workflow_change_logs: `Workflow change logs`,
  assign_workflow: `Assign workflow`,
  assign_workflow_to_organization_users: `Assign this document workflow to sub users of your organization`,
  approvers: `Approvers`,
  assign: `Assign`,
  workflow_doc_pending_approval: `Workflow document approval pending`,
  workflow_doc_deleted: `Workflow document deleted`,
  workfow_doc_approvalnotrequired_attached: `Workflow document approval not required attached`,
  workflow_assigned_successfully: `Workflow assigned successfully`,
  you_do_not_have_any_workflows_yet: `You do not have any workflows yet`,
  no_workflows_found_for_the_selected_filters: `No workflows found for the selected filters`,
  approve: "Approve",
  reject: "Reject",
  approvalRequired: "Approval Required",
  approved: "Approved",
  rejected: "Rejected",
  reviewRequired: "Review Required",
  pending_approval: `Pending Approval`,
  approval_not_required: `Approval not required`,
  workflow_documents_pending_approval: `Workflow document pending approval`,
  approval_status: `Approval status`,
};

export default workflows;
