import useLocales from "src/hooks/useLocales";

export function useErrorTranslation() {
  const { translate } = useLocales();
  const translateErrorMessage = (errorMessage) => {
    let translatedErrorMessage = errorMessage;
    if (errorMessage.includes("accountClass should be one of")) {
      const documentId = errorMessage.match(/'([^']+)'/)[1];
      translatedErrorMessage = translate("accountClassErrorMessage", {
        documentId,
      });
    }
    if (
      errorMessage.includes("has duplicate field") &&
      errorMessage.includes("document")
    ) {
      const documentId = errorMessage.match(/'([^']+)'/)[1];
      translatedErrorMessage = translate("duplicateFieldErrorMessage", {
        documentId,
      });
    } else if (errorMessage.includes("is missing")) {
      const fieldName = errorMessage.match(/'([^']+)'/)[1];
      translatedErrorMessage = translate("missingFieldErrorMessage", {
        fieldName,
      });
    }
    if (translatedErrorMessage.includes("review_accountingperiod_closed")) {
      return translate("review_accountingperiod_closed");
    }
    if (translatedErrorMessage.indexOf("duplicateFieldErrorMessage") == -1) {
      return translatedErrorMessage;
    }
    if (
      translatedErrorMessage.includes(
        "accountClass should be on of'AccountReceivable,AccountPayable'"
      )
    ) {
      const documentId = errorMessage.match(/'([^']+)'/)[1];

      return translate("accountClassErrorMessage", { documentId: documentId });
    }
    return translate(errorMessage);
  };
  return { translateErrorMessage };
}
