import { Box, Typography } from "@mui/material";
import WorkflowIllustrations from "src/assets/illustrations/WorkflowIllustrations";
import useLocales from "src/hooks/useLocales";

const EmptyWorkflows = ({ isFilterEmpty }) => {
  const { translate } = useLocales("workflow_screen");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <WorkflowIllustrations />
        </Box>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {isFilterEmpty
            ? translate(`no_workflows_found_for_the_selected_filters`)
            : translate(`you_do_not_have_any_workflows_yet`)}
        </Typography>
      </Box>
    </>
  );
};

export default EmptyWorkflows;
