import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import useLocales from "src/hooks/useLocales";
import Table, { ApprovalDocuments } from "./table";

interface ApprovalModalProps extends ApprovalDocuments {
  open: boolean;
  handleClose: (id?: string) => void;
  setFileId: Function;
  setDocument: Dispatch<
    SetStateAction<{ id: string; workflowId: string; documentId: string }>
  >;
}
const ApprovalModal = (props: ApprovalModalProps) => {
  const { open, handleClose, setFileId, workflowId, setDocument } = props;
  const { translate } = useLocales("workflow_screen");
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth={"lg"}
      scroll="body"
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: 2,
            pr: 3.5,
          }}
        >
          {translate("workflow_documents_pending_approval")}
          <IconButton
            sx={{ width: 10, height: 8 }}
            onClick={() => handleClose()}
          >
            <CloseModalButton />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Table setDocument={setDocument} {...{ workflowId }} />
      </DialogContent>
    </Dialog>
  );
};
export default ApprovalModal;
