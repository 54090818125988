const workflows = {
  workflow_history: `Historial de flujo de trabajo`,
  workflow_updated: `Flujo de trabajo actualizado`,
  new_workflow: `Nuevo flujo de trabajo`,
  old_workflow: `Antiguo flujo de trabajo`,
  workflow_change_logs: `Registros de cambios del flujo de trabajo`,
  assign_workflow: `Asignar flujo de trabajo`,
  assign_workflow_to_organization_users: `Asignar este flujo de trabajo del documento a los subusuarios de su organización`,
  approvers: `Aprobadores`,
  assign: `Asignar`,
  workflow_doc_pending_approval: `Aprobación pendiente del documento de flujo de trabajo`,
  workflow_doc_deleted: `Documento de flujo de trabajo eliminado`,
  workfow_doc_approvalnotrequired_attached: `Aprobación del documento de flujo de trabajo no requerida adjunta`,
  workflow_assigned_successfully: `Flujo de trabajo asignado con éxito`,
  you_do_not_have_any_workflows_yet: `Aún no tienes ningún flujo de trabajo`,
  no_workflows_found_for_the_selected_filters: `No se encontraron flujos de trabajo para los filtros seleccionados`,
  approve: "Aprobar",
  reject: "Rechazar",
  approvalRequired: "Aprobación requerida",
  approved: "Aprobado",
  rejected: "Rechazado",
  reviewRequired: "Revisión requerida",
  pending_approval: `Aprobación pendiente`,
  approval_not_required: `Aprobación no requerida`,
  workflow_documents_pending_approval: `Documento de flujo de trabajo pendiente de aprobación`,
  approval_status: `Estado de aprobación`,
};
export default workflows;
