import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as SearchIcon } from "src/assets/icon/search.svg";
import FilterMenu from "src/components/filter/FilterMenu";
import NewTextField from "src/components/form/TextField";
import MyDatePicker from "src/components/pickers/DatePicker";
import useLocales from "src/hooks/useLocales";
import { fDate } from "src/utils/formatTime";

const SearchFilters = ({
  loader,
  filterState,
  setFilterState,
  optionsStatus,
  optionsApprovalStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { translate } = useLocales();
  const { handleSubmit } = useForm();
  const defaultApprovalStatus = {
    name: "approvalStatus",
    value: "0",
    displayValue: "Approval required",
    defaultValue: "",
    label: "Approval status",
  };
  const [filters, setFilters] = useState<any>({
    approvalStatus: defaultApprovalStatus,
  });
  const [initialValues, setInitialValues] = useState<any>(filterState);
  const theme = useTheme();
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let rows = Math.floor(
    ((inputRef.current as HTMLTextAreaElement)?.scrollHeight - 5) / 24
  );

  const handleChange = (name: string, value: any) => {
    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };

  const optionsStatusMap = {
    "": `${translate(`finance_screen.all`)}`,
    "1": `${translate(`status.processed`)}`,
    "4": `${translate(`status.need_review`)}`,
    "5": `${translate(`status.reviewed`)}`,
    "6": `${translate(`status.duplicate`)}`,
    "2": `${translate(`status.failed`)}`,
  };

  const optionsApprovalStatusMap = {
    "": `${translate(`finance_screen.all`)}`,
    "0": `${translate(`Approval required`)}`,
    "99999": `${translate(`approval not required`)}`,
    "1": `${translate(`approved`)}`,
    "2": `${translate(`rejected`)}`,
  };
  const filterSettersMap = {
    documentStatus: (value = "") => {
      filters.documentStatus = {
        name: "documentStatus",
        value: value,
        displayValue: optionsStatusMap[value],
        defaultValue: "",
        label: `Document status`,
      };
    },
    fromDate: (value = "") => {
      filters.fromDate = {
        name: "fromDate",
        value: value,
        displayValue: fDate(value),
        defaultValue: null,
        label: `${translate(`finance_screen.start_date`)}`,
      };
    },
    toDate: (value = "") => {
      filters.toDate = {
        name: "toDate",
        value: value,
        displayValue: fDate(value),
        defaultValue: null,
        label: `${translate(`finance_screen.end_date`)}`,
      };
    },
    documentName: (value = "") => {
      filters.documentName = {
        name: "documentName",
        value: value,
        displayValue: value,
        defaultValue: "",
        label: `${translate(`finance_screen.document_name`)}`,
      };
    },
    approvalStatus: (value = "") => {
      filters.approvalStatus = {
        name: "approvalStatus",
        value: value,
        displayValue: optionsApprovalStatusMap[value],
        defaultValue: "",
        label: `Approval status`,
      };
    },
  };

  const handleFilters = (initialValues: any) => {
    Object.entries(initialValues).forEach(([key, value]) => {
      if (value) {
        filterSettersMap?.[key]?.(value);
      } else {
        delete filters[key];
      }
    });
    setFilters(filters);
  };

  const handleOnDelete = (k: string) => {
    setFilterState({
      ...initialValues,
      [k]: filters[k]?.defaultValue,
    });
    setInitialValues({
      ...initialValues,
      [k]: filters[k]?.defaultValue,
    });
    delete filters[k];
    setFilters(filters);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        py: 1,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "70%",
            lg: "70%",
            xl: "50%",
          },
          py: 1,
        }}
      >
        <FilterMenu
          filters={filters}
          inputRef={inputRef}
          anchorEl={anchorEl}
          open={open}
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          handleOnDelete={handleOnDelete}
          rows={rows}
          loader={loader}
        >
          <form
            onSubmit={handleSubmit(() => {
              setFilterState(initialValues);
              handleFilters(initialValues);
              handleCloseMenu();
            })}
          >
            <Box>
              <InputLabel
                htmlFor="demo-customized-textbox"
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  transform: "translate(0, 1.5px) scale(0.75)",
                  color: theme.palette.action.active,
                }}
              >
                {translate(`Document status`)}
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "8px",
                  py: 1,
                }}
              >
                {optionsStatus?.map((item) => {
                  return (
                    <Chip
                      label={translate(item.value)}
                      size="small"
                      onClick={() => {
                        handleChange("documentStatus", item?.enum);
                      }}
                      sx={{
                        borderRadius: "4px",
                        height: "20px",
                        fontSize: "12px",
                        bgcolor:
                          initialValues?.documentStatus === item?.enum
                            ? theme?.palette?.secondary?.light
                            : "white",
                        border: `1px solid ${
                          initialValues?.documentStatus === item?.enum
                            ? theme?.palette?.secondary?.main
                            : theme?.palette?.action.disabled
                        }`,
                        pointerEvents: "auto",
                      }}
                    />
                  );
                })}
              </Box>
              <Divider light />
            </Box>
            <Grid
              container
              spacing={2}
              // direction={{ xs: "column", sm: "column", md: "row" }}
              sx={{
                py: 1,
                display: "flex",
                flexWrap: {
                  xs: "wrap",
                  sm: "wrap",
                  md: "nowrap",
                },
              }}
            >
              <Grid item xs={12} sm={6}>
                <MyDatePicker
                  sx={{
                    fontSize: "12px",
                    padding: "4px 26px 2px 10px",
                  }}
                  label={`${translate(`finance_screen.start_date`)}`}
                  value={initialValues?.fromDate}
                  disabled={loader}
                  onChange={(newValue) => {
                    handleChange("fromDate", fDate(newValue));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyDatePicker
                  sx={{
                    fontSize: "12px",
                    padding: "4px 26px 2px 10px",
                  }}
                  label={`${translate(`finance_screen.end_date`)}`}
                  value={initialValues?.toDate}
                  disabled={loader}
                  onChange={(newValue) => {
                    handleChange("toDate", fDate(newValue));
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              // direction={{ xs: "column", sm: "column", md: "row" }}
              sx={{
                py: 1,
                display: "flex",
                flexWrap: {
                  xs: "wrap",
                  sm: "wrap",
                  md: "nowrap",
                },
              }}
            >
              <Grid item xs={12} sm={6}>
                <Select
                  value={initialValues?.approvalStatus}
                  disabled={loader}
                  fullWidth
                  displayEmpty
                  input={<NewTextField label={translate(`approvalStatus`)} />}
                  onChange={(event: SelectChangeEvent) => {
                    handleChange("approvalStatus", event.target.value);
                  }}
                >
                  {optionsApprovalStatus?.map((item) => {
                    return (
                      <MenuItem key={item} value={item.enum}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NewTextField
                  id="outlined-controlled"
                  label={translate(`finance_screen.document_name`)}
                  value={initialValues?.documentName}
                  disabled={loader}
                  onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                    handleChange("documentName", event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="small"
                startIcon={<SearchIcon stroke="white" height={20} width={20} />}
                type="submit"
                sx={{ width: "fit-content" }}
              >
                {translate(`finance_screen.search`)}
              </Button>
            </Box>
          </form>
        </FilterMenu>
      </Box>
    </Box>
  );
};

export default SearchFilters;
