import {
  Box,
  Button,
  Container,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { WorkflowsEntity } from "src/@types/workflows/workflows";
import EyeOpenIcon from "src/assets/icon/eye/On";
import PlusIcon from "src/assets/icon/plus";
import { GetAsync, PatchAsyncNew } from "src/common/httpRequests";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import MyTablePagination from "src/components/pagination/TablePagination";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import usePermissions from "src/hooks/usePermissions";
import { PATH_DASHBOARD } from "src/routes/paths";
import EmptyWorkflows from "./EmptyWorflows";
import WorkflowModal from "./Workflowmodal";
import ApprovalModal from "./approvedDocuments/ApprovalModal";
import CustomCard from "./components/CustomCard";
import SearchFilters from "./components/Filters";
import CustomCardSkeleton from "./components/skeleton/CustomCard";
import WorkflowsSkeleton from "./components/skeleton/workflows";
import HistoryDrawer from "./historyDrawer";
import useWorkflowFilter from "./hooks/useWorkflowFilter";
import ReviewModal from "./review/reviewModal";
import { Action } from "./type";
export interface WorkflowController {
  page: number;
  rowsPerPage: number;
}

export const WorkflowContext = createContext<WorkflowContextType>({
  fileId: "",
  approvalNotRequired: false,
  setFileId: () => {},
  setApprovalNotRequired: () => {},
  handleOpenReviewModal: () => {},
});
type WorkflowContextType = {
  fileId: string;
  approvalNotRequired: boolean;
  setFileId: Function;
  setApprovalNotRequired: Dispatch<SetStateAction<boolean>>;
  handleOpenReviewModal: Function;
};

const Workflows = () => {
  const [searchParams] = useSearchParams();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { permissionHandle } = usePermissions();
  const { filterState, setFilterState } = useWorkflowFilter({
    record: 5,
    page: 0,
  });

  const optionsAllowReviewStatus = [
    { enum: "", value: `${translate(`All`)}` },
    { enum: "true", value: `${translate(`Yes`)}` },
    { enum: "false", value: `${translate(`No`)}` },
  ];

  const [isLoading, setLoading] = useState(false);
  const [SkeletonLoading, setSkeletonLoading] = useState(true);
  const [workflowTable, setWorkflowTable] = useState<WorkflowsEntity[]>([]);
  const [openWorkflowModal, setOpenWorkflowModal] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [action, setAction] = useState<Action>("");
  const [fileId, setFileId] = useState<string>("");
  const [approvalNotRequired, setApprovalNotRequired] = useState(false);
  const [openDocumentsModal, setOpenDocumentsModal] = useState<boolean>(
    searchParams.get("documents") === "true"
  );
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false);
  const [workflowId, setWorkflowId] = useState<string>("");
  const [approvers, setApprovers] = useState<string[]>([]);
  const [isFilterEmpty, setIsFilterEmpty] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filteredRecord, setFilteredRecords] = useState(0);
  const [document, setDocument] = useState<{
    id: string;
    workflowId: string;
    documentId: string;
  }>({
    id: "",
    workflowId: "",
    documentId: "",
  });
  const [isHistoryDrawerOpen, setIsHistoryDrawer] = useState(false);

  const handleDocumentsModal = (id?: string) => {
    if (!openDocumentsModal && id) {
      setWorkflowId(id);
    } else {
      setWorkflowId("");
    }
    setOpenDocumentsModal(!openDocumentsModal);
  };

  const handleHistoryDrawer = (id?: string) => {
    if (!openDocumentsModal && id) {
      setWorkflowId(id);
    } else {
      setWorkflowId("");
    }
    setIsHistoryDrawer(!isHistoryDrawerOpen);
  };

  const handleCloseAction = () => {
    if (id) {
      setId("");
    }
    setOpenWorkflowModal(false);
  };

  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
  };

  const handleOpenAction = (action: Action, id?: string) => {
    if (id) {
      setId(id);
    }
    setAction(action);
    setOpenWorkflowModal(true);
  };

  const getWorkFlowsApi = async (filterState) => {
    return GetAsync(
      `${API_GATEWAY}/api/workflowservice/workflows`,
      true,
      undefined,
      {
        params: {
          page: filterState ? filterState?.page + 1 : 1,
          record: filterState?.record,
          name: filterState?.name || undefined,
          approver: filterState?.approver || undefined,
          allowReviewDocument: filterState?.allowReviewDocument || undefined,
        },
      }
    )
      .then(async (res) => {
        if (res?.data) {
          let workflowsT: WorkflowsEntity[] = [];
          if (res?.data?.workflows?.length > 0) {
            res?.data?.workflows?.forEach((workF: WorkflowsEntity) => {
              workflowsT.push({
                ...workF,
              });
            });
            setWorkflowTable(workflowsT);
            setTotalRecords(res?.data?.totalRecord);
            setFilteredRecords(res?.data?.filteredRecord);
            setApprovers(res?.data?.approvers);
          } else {
            setWorkflowTable([]);
            setTotalRecords(res?.data?.totalRecord);
            setFilteredRecords(0);
            setIsFilterEmpty(
              res?.data?.filteredRecord === 0 && res?.data?.totalRecord > 0
                ? true
                : false
            );
          }
        }
      })
      .catch((err) => {
        if (err?.message) {
          enqueueSnackbar(`${translate(`${err?.message}`)}`, {
            variant: "error",
          });
        }
      });
  };

  const deleteWorkflow = async (e: string) => {
    PatchAsyncNew(`${API_GATEWAY}/api/workflowservice/workflows/activate`, {
      id: e,
      activate: false,
    })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          enqueueSnackbar(
            translate("workflows_screen.alertDeactivateMessage"),
            {
              variant: "success",
            }
          );
          setLoading(false);
          getWorkFlowsApi(filterState);
        } else {
          enqueueSnackbar(translate(`${res.data.Detail}`), {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  function handleGetWorkflowAfterDelete() {
    const lastPage = Math.ceil(totalRecords / filterState.record);

    const totalRows = filterState.record * lastPage - totalRecords;
    const lastPageRecords = filterState.record - totalRows;

    console.log({ lastPage, totalRows, lastPageRecords, filterState });
    if (
      lastPage === filterState.page + 1 &&
      lastPageRecords === 1 &&
      filterState.page !== 0
    ) {
      setFilterState({ ...filterState, page: filterState.page - 1 });
    } else if (
      lastPage === filterState.page + 1 &&
      lastPageRecords === 1 &&
      filterState.page === 0
    ) {
      setFilterState({
        name: "",
        approver: "",
        allowReviewDocument: "",
        page: 0,
        record: 5,
      });
    } else {
      getWorkFlowsApi(filterState);
    }
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      getWorkFlowsApi(filterState).finally(() => setSkeletonLoading(false));
    } else {
      setLoading(true);
      getWorkFlowsApi(filterState).finally(() => setLoading(false));
    }
  }, [filterState]);

  const readWorkflowApproval = permissionHandle([
    "WorkflowApproval_Read",
    "WorkflowApproval_Write",
  ]);

  const writeWorkflow = permissionHandle(["WorkflowManagement_Write"]);

  const readPage = permissionHandle([
    "WorkflowManagement_Read",
    "WorkflowManagement_Write",
    "WorkflowApproval_Read",
    "WorkflowApproval_Write",
  ]);

  if (!readPage) {
    return <Navigate to="/403" />;
  }

  return (
    <WorkflowContext.Provider
      value={{
        fileId,
        approvalNotRequired,
        setFileId,
        setApprovalNotRequired,
        handleOpenReviewModal,
      }}
    >
      <Page title={translate("Workflows")}>
        <ApprovalModal
          open={openDocumentsModal}
          handleClose={handleDocumentsModal}
          setFileId={setFileId}
          workflowId={workflowId}
          setDocument={setDocument}
        />
        <ReviewModal
          open={openReviewModal}
          handleClose={handleCloseReviewModal}
          fileId={fileId}
          approvalNotRequired={approvalNotRequired}
          renderDocument={() => getWorkFlowsApi(filterState)}
          documentId={document.documentId}
          workflowId={document.workflowId}
          id={document.id}
        />
        {isHistoryDrawerOpen && (
          <HistoryDrawer
            openDrawer={isHistoryDrawerOpen}
            handleDrawerClose={handleHistoryDrawer}
            id={workflowId}
          />
        )}

        {SkeletonLoading ? (
          <WorkflowsSkeleton writePermission={writeWorkflow} />
        ) : (
          <Container maxWidth={"lg"}>
            <HeaderBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: PATH_DASHBOARD.root,
                },
                { name: `${translate("Workflows")}` },
              ]}
            />

            {["add", "duplicate", "edit"].includes(action) && (
              <WorkflowModal
                {...{
                  open: openWorkflowModal,
                  handleClose: handleCloseAction,
                  action,
                  getWorkFlowsApi: () =>
                    getWorkFlowsApi({
                      page: 0,
                      record: filterState?.record,
                    }),
                  id,
                }}
              />
            )}

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  direction: "row",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "primary.main",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    mt: 1,
                  }}
                >
                  {translate("Workflow settings")}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    disabled={!readWorkflowApproval}
                    onClick={() => handleDocumentsModal()}
                    startIcon={
                      <EyeOpenIcon
                        height={20}
                        width={20}
                        customColor={
                          !readWorkflowApproval
                            ? theme.palette.grey[500]
                            : theme.palette.text.secondary
                        }
                      />
                    }
                  >
                    {translate("View all documents")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!writeWorkflow}
                    size="medium"
                    onClick={() => handleOpenAction("add")}
                    startIcon={
                      <PlusIcon
                        customColor={
                          !writeWorkflow
                            ? theme.palette.grey[500]
                            : theme.palette.background.paper
                        }
                        height={20}
                        width={20}
                      />
                    }
                  >
                    {translate("New workflow")}
                  </Button>
                </Box>
              </Box>
              {permissionHandle([
                "WorkflowManagement_Read",
                "WorkflowManagement_Write",
              ]) && (
                <>
                  {totalRecords > 0 && (
                    <SearchFilters
                      {...{
                        loader: isLoading,
                        searchApi: getWorkFlowsApi,
                        filterState,
                        setFilterState,
                        count: filteredRecord,
                        approvers,
                        optionsAllowReviewStatus,
                      }}
                    />
                  )}
                  {filteredRecord === 0 ? (
                    <>
                      <EmptyWorkflows isFilterEmpty={isFilterEmpty} />
                    </>
                  ) : isLoading ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexWrap: "wrap",
                          pb: 2,
                          direction: "row",
                        }}
                      >
                        {Array.from(new Array(totalRecords || 5)).map(
                          (item, i) => (
                            <CustomCardSkeleton key={i} />
                          )
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        {workflowTable && workflowTable.length > 0 ? (
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 2,
                                flexWrap: "wrap",
                                pb: 2,
                                direction: "row",
                              }}
                            >
                              {workflowTable?.map((item, i) => (
                                <CustomCard
                                  {...{
                                    key: item.id,
                                    disabled: !writeWorkflow,
                                    disableApproval: !readWorkflowApproval,
                                    item,
                                    handleGetWorkflowAfterDelete,
                                    handleDeleteWorkflow: deleteWorkflow,
                                    handleOpenAction: handleOpenAction,
                                    handleHistoryDrawer,
                                    handleDocumentsModal: (id) => {
                                      handleDocumentsModal(id);
                                    },
                                  }}
                                />
                              ))}
                            </Box>
                            <MyTablePagination
                              border={false}
                              count={filteredRecord || 10}
                              rowsPerPage={filterState?.record || 10}
                              page={filterState?.page || 0}
                              onPageChange={(event, page) => {
                                setFilterState({
                                  ...filterState,
                                  page: page,
                                });
                              }}
                              onRowsPerPageChange={(
                                event: SelectChangeEvent<any>
                              ) => {
                                setFilterState({
                                  ...filterState,
                                  page: 0,
                                  record: parseInt(event.target.value, 10),
                                });
                              }}
                            />
                          </Box>
                        ) : (
                          <EmptyWorkflows isFilterEmpty={isFilterEmpty} />
                        )}
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </Container>
        )}
      </Page>
    </WorkflowContext.Provider>
  );
};

export default Workflows;
